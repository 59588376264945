<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('entidades.entidad_form.editar_entidad')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('entidades.entidad_form.entidad')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('entidades.entidad_form.nueva_entidad')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      @loadItem="loadItem"
      :beforeSave="beforeSave"
      :readonly="readonly"
      :dbAdapter="Entidad"
      :defaultData="{entidad:'DigitalTPM', formasCobro:[], cuentas:[]}"
    >
      <f7-block-title>
        {{$t('entidades.entidad_form.datos_entidad')}}
      </f7-block-title>
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <f7-row>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('entidades.entidad_form.cif')"
                    :form="rform"
                    type="text"
                    name="cif"
                  ></r-input>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('entidades.entidad_form.nombre')"
                    :form="rform"
                    type="text"
                    name="nombre"
                  ></r-input>
                </f7-row>
                <r-input
                  floating-label
                  :label="$t('entidades.entidad_form.eMail')"
                  :form="rform"
                  type="text"
                  name="email"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('entidades.entidad_form.razonSocial')"
                  :form="rform"
                  type="text"
                  name="razonSocial"
                ></r-input>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <f7-block-title>
        {{$t('entidades.entidad_form.direcciones')}}
      </f7-block-title>
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <div class="label-group">{{$t('entidades.entidad_form.direccionSocial')}}</div>
                <r-direccion
                  :form="rform"
                  name="direccionSocial">
                </r-direccion>
              </ul>
            </f7-col>
            <f7-col width="100" medium="50">
              <ul>
                <div class="label-group">{{$t('entidades.entidad_form.direccionFacturacion')}}</div>
                <r-direccion
                  :form="rform"
                  name="direccionFacturacion">
                </r-direccion>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <f7-block-title>
        {{$t('entidades.entidad_form.usuariosAsociados')}} <f7-link v-if="!rform.readonly" @click="rform.formData.usuariosAsociados.push({})">{{$t('facturas.add')}}</f7-link>
      </f7-block-title>
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list no-hairlines" v-for="(item,index) in rform.formData.usuariosAsociados"
              :key="'usuarioasociado-'+index" :value="item">
            <f7-col width="100" medium="30">
              <ul>
                <r-input
                  floating-label
                  :label="$t('entidades.entidad_form.usuario')"
                  :form="rform"
                  type="text"
                  :name="'usuariosAsociados.'+index+'.username'"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="20">
              <ul>
                <r-input
                  floating-label
                  :label="$t('entidades.entidad_form.rol')"
                  :form="rform"
                  type="select"
                  :name="'usuariosAsociados.'+index+'.entidadActual.rol'"
                >
                  <option selected disabled></option>
                  <option v-for="(rol, rolIndex) in roles"
                      :key="'usuarioasociado-'+index+'rol-'+rolIndex" :value="rol.valor">{{rol.nombre}}</option>
                </r-input>
              </ul>
            </f7-col>
            <f7-link
              v-if="!rform.readonly"
              @click="$delete(rform.formData.usuariosAsociados,index)"
              class="margin-half"
              style="position:absolute;right:0;top:0;"
              icon-f7="trash"
            ></f7-link>
            <f7-col width="100" medium="50"></f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>

    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .client-logo {
    margin-top: 3em;
  }
  .client-logo img {
    margin: -2em 25% 0;
    width: 50%;
  }
</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rDireccion from "./../../components/rDireccion.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import Entidad from "./../../js/db/Entidad.js";
import Combo from "./../../js/db/Combo.js";
import Documento from "./../../js/db/Documento.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rDireccion,
    rAutocomplete
  },
  data: function() {
    var self = this;
    return {
      Entidad,
      Documento,
      roles: [],
      algunCambioEnConf: false,
      readonly: !this.editable,
      canManage: false,
      newImagenSelected : false
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    beforeSave: function(data, resolve, reject) {
      var formData = Object.assign({}, data);

      formData.usuariosAsociados.forEach(usuarioAsociado => {
        usuarioAsociado.entidades = [
          {
            idEntidad: data.id,
            rol: usuarioAsociado.entidadActual.rol
          }
        ];
      });
      resolve(formData);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      self.loadUsers(res);
      app.toast
        .create({
          text: self.$t("entidades.entidad_form.cliente_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    loadItem: function(res) {
      var self = this;
      self.loadUsers(res);
    },
    loadUsers: function(res) {
      var self = this;
      var app = self.$f7;
      Entidad.usuarios(res.id)
        .then(function(usuarios) {
          usuarios.forEach(el=>{
            el.entidadActual = el.entidades.filter(entidad => entidad.idEntidad == res.id).shift();
          });
          return usuarios;
        })
        .then(function(usuarios) {
          self.$set(res, 'usuariosAsociados', usuarios);
        })
        .catch(function(e) {
          console.log(e);
          app.dialog.alert(e.message);
        })
        .finally(function() {
          app.preloader.hide();
        });
    }
  },
  beforeMount: function(){
      var self = this;
    Combo.rolesUsuarioEntidad().then(res=>self.roles = res);
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>