<template>
  <f7-popup ref="popupAsociarPedido" class="popup-asociar-pedido" @popup:closed="$refs.rformAsociarPedido.clear();" swipe-to-close>
    <f7-page>
      <f7-navbar :title="$t('facturas.asociar_pedido')">
        <f7-nav-right>
          <f7-link popup-close>{{$t('cerrar')}}</f7-link>
        </f7-nav-right>
      </f7-navbar>

      <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
    <f7-card
      class="elevation-3 padding-vertical-half padding-right"
    >
    <r-form
      ref="rformAsociarPedido"
      v-slot="{rform}"
      :itemId="factura?factura.id:null"
      :readonly="false"
      :dbAdapter="Factura"
    >
      <f7-card-content>
        <f7-list class="list-form">
          <ul>
            <f7-row>
              <f7-col width="100" medium="100">
                <r-autocomplete
                  ref="pedido"
                  class="padding-vertical"
                  :dbAdapter="Pedido"
                  :filter="filterPedido"
                  floating-label
                  :label="$t('facturas.pedido')"
                  :form="rform"
                  name="pedidoAsociadoId"
                  idProp="id"
                  labelProp="name"
                  :source="function(self) {
                    return function(query, render) {
                      var results = [];
                      for (var i = 0; i < self.items.length; i++) {
                          if (!Pedido.isFinalizado(self.items[i])
                            && self.items[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0
                            && (!self.filter.clienteId || self.items[i].clienteId == self.filter.clienteId)) {
                              self.items[i]['txtValue'] = self.items[i].name;
                              results.push(self.items[i]);
                            }
                      }
                      render(results);
                    };
                  }"
                ></r-autocomplete>
              </f7-col>
            </f7-row>
          </ul>
        </f7-list>
      </f7-card-content>
    </r-form>
    <f7-button
        fill
        icon-f7="floppy_disk"
        :text="$t('guardar')"
        class="display-flex margin-left"
        @click="asociarFactura"
      ></f7-button>
    </f7-card>
      <!-- </div> -->
    </f7-page>
  </f7-popup>
</template>
<style scoped>
.label-group {
  margin-top: 20px;
  margin-left: 20px;
  font-weight: bold;
}
</style>
<script>
import rForm from "./../../components/rForm.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Factura from "./../../js/db/Factura.js";
import Pedido from "./../../js/db/Pedido.js";
export default {
  components: {
    rForm,
    rAutocomplete
  },
  data() {
    return {
      Factura,
      Pedido
    };
  },
  props: {
    factura: {
      required: true
    }
  },
  computed: {
    filterPedido: function(){
      var self = this;
      if(self.factura && self.factura.clienteId){
        return { clienteId: self.factura.clienteId};
      }
      return {};
    }
  },
  methods: {
    asociarFactura: function(e) {
      var self = this;
      var app = self.$f7;
      var data = self.$refs.rformAsociarPedido.formData;
      app.preloader.show();
      Factura.asociarPedido(self.factura.id, data.pedidoAsociadoId).then(function(res) {
        if (res) {
          app.toast
            .create({
              icon: '<i class="f7-icons">checkmark</i>',
              text: self.$t('facturas.pedido_asociado_correctamente'),
              position: "center",
              destroyOnClose: true,
              closeTimeout: 2000
            }).open();
            self.$refs.rformAsociarPedido.clear();
            if (self.$refs.popupAsociarPedido) {
              self.$refs.popupAsociarPedido.close(true);
            }
            self.$emit("save", res);
        }
      }).catch(function(error){
        app.dialog.alert(error.message);
      }).finally( _ => app.preloader.hide());
    }
  }
};
</script>
