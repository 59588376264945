import DatabaseModel from './DatabaseModel.js';

var Combo = Object.create(DatabaseModel);

Combo.model = 'combos';

Combo.rolesUsuarioEntidad = function() {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_contabilidad + self.prefix + self.model + '/getRolesUsuarioEntidad',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json'
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.list);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

export default Combo;