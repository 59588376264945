import Facturas from './facturas.vue';
import FacturaForm from './factura-form.vue';
import FacturaNew from './factura-new.vue';
import RoutesUtils from '../../js/RoutesUtils.js';

var routes = [{
        path: '/facturas/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('facturas', ['ADMIN'], Facturas),
        //keepAlive: true
    },
    {
        path: '/facturas/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('facturas/new', ['ADMIN'], FacturaNew)
    },
    {
        path: '/facturas/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('facturas/edit', ['ADMIN'], FacturaForm)
    },
    {
        path: '/facturas/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('facturas/view', ['ADMIN'], FacturaForm, { editable: false })
    },
];
export default routes;