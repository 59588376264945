<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('clientes.cliente_form.editar_cliente')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('clientes.cliente_form.cliente')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('clientes.cliente_form.nuevo_cliente')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :readonly="readonly"
      :dbAdapter="Cliente"
      :defaultData="{entidad:'DigitalTPM'}"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 3000">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <div class="label-group">{{$t('clientes.cliente_form.datos_cliente')}}</div>
                <f7-row>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('clientes.cliente_form.cif')"
                    :form="rform"
                    type="text"
                    name="cif"
                  ></r-input>
                  <r-input
                    class="col-50"
                    floating-label
                    :label="$t('clientes.cliente_form.nombre')"
                    :form="rform"
                    type="text"
                    name="nombre"
                  ></r-input>
                </f7-row>
                <r-input
                  floating-label
                  :label="$t('clientes.cliente_form.eMail')"
                  :form="rform"
                  type="text"
                  name="email"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('clientes.cliente_form.razonSocial')"
                  :form="rform"
                  type="text"
                  name="razonSocial"
                ></r-input>
                <f7-row>
                  <f7-col width="75"></f7-col>
                  <r-input
                    class="col-25"
                    floating-label
                    :label="$t('clientes.cliente_form.precioHabitual')"
                    :form="rform"
                    type="number"
                    name="precioHabitual"
                  ></r-input>
                </f7-row>
              </ul>
            </f7-col>
            <f7-col width="100" medium="50">
              <ul>
                <div class="label-group">{{$t('clientes.cliente_form.direccion')}}</div>
                <f7-row>
                  <f7-col width="25">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.codigo_postal')"
                      :form="rform"
                      type="text"
                      name="direccion.cp"
                    ></r-input>
                  </f7-col>
                  <f7-col width="75">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.direccion')"
                      :form="rform"
                      type="text"
                      name="direccion.direccion"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.poblacion')"
                      :form="rform"
                      type="text"
                      name="direccion.poblacion"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.provincia')"
                      :form="rform"
                      type="text"
                      name="direccion.provincia"
                    ></r-input>
                  </f7-col>
                  <f7-col width="33">
                    <r-input
                      floating-label
                      :label="$t('clientes.cliente_form.pais')"
                      :form="rform"
                      type="text"
                      name="direccion.pais"
                    ></r-input>
                  </f7-col>
                </f7-row>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>

    </r-form>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .client-logo {
    margin-top: 3em;
  }
  .client-logo img {
    margin: -2em 25% 0;
    width: 50%;
  }
</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import Cliente from "./../../js/db/Cliente.js";
import Documento from "./../../js/db/Documento.js";

export default {
  components: {
    rForm,
    rInput,
    rTimepicker,
    rFiles,
    rAutocomplete
  },
  data: function() {
    var self = this;
    return {
      Cliente,
      Documento,
      algunCambioEnConf: false,
      readonly: !this.editable,
      newImagenSelected : false
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("clientes.cliente_form.cliente_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>