import DatabaseModel from './DatabaseModel.js';
import Factura from './Factura.js';

var Pedido = Object.create(DatabaseModel);

Pedido.model = 'pedidos';

Pedido.delete = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server_contabilidad + self.prefix + self.model + '/' + params.itemId + '/cancel',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}
Pedido.cerrar = function(id) {
    var self = this;
    if (!id) reject({ error: 'itemId not defined' });
    var params = {
        url: self.server_contabilidad + self.prefix + self.model + '/cerrar/' + id + '/',
        method: 'PUT',
        data: {}
    };
    return self.sendRequest(params, false);
}

// Valores calculados
Pedido.getCodigo = function(pedido){
    return pedido.code || "-";
}
Pedido.getSubtotal = function(pedido){
    if(!pedido.lineas){
      return [];
    }
    return pedido.lineas.map(linea => {
      if(typeof linea.horas === "undefined" || typeof linea.precio === "undefined"){
        return "";
      }
      return linea.horas * linea.precio;
    });
}
Pedido.getImporteTotal = function(pedido){
    var subtotales = Pedido.getSubtotal(pedido);
    if(!subtotales){
      return 0;
    }
    return subtotales.reduce((total, subt) => total + subt,0);
}
Pedido.getFacturado = function(pedido){
    if(!pedido || !pedido.facturasAsociadas){
      return 0;
    }
    return Object.values(pedido.facturasAsociadas).filter(factura => Factura.isFacturado(factura)).map(factura => factura.totales.subtotal).reduce((t, subtotal) => t + parseFloat(subtotal), 0);
}
Pedido.getPendienteFacturar = function(pedido){
    return  Pedido.getImporteTotal(pedido) - Pedido.getFacturado(pedido);
}
Pedido.getCobrado = function(pedido){
    if(!pedido || !pedido.facturasAsociadas){
      return 0;
    }
    return Object.values(pedido.facturasAsociadas).filter(factura => Factura.isCobrado(factura)).map(factura => factura.totales.subtotal).reduce((t, subtotal) => t + parseFloat(subtotal), 0);
}
Pedido.getPendienteCobrar = function(pedido){
    return  Pedido.getImporteTotal(pedido) - Pedido.getCobrado(pedido);
}
Pedido.getHorasTotales = function(pedido){
    if(!pedido || !pedido.lineas){
        return 0;
    }
    return Object.values(pedido.lineas).filter(linea => typeof linea.horas !== "undefined").reduce((t, {horas}) => t + parseInt(horas), 0);
}
Pedido.isListo = function(pedido){
    return Pedido.getImporteTotal(pedido) == Pedido.getCobrado(pedido) && pedido.facturasAsociadas && !Pedido.isFinalizado(pedido) && !pedido.facturasAsociadas.some(factura => !Factura.isFinalizado(factura));
}
Pedido.isCerrado = function(pedido){
    return pedido.estado && pedido.estado.estado && pedido.estado.estado == "CERRADO";
}
Pedido.isFinalizado = function(pedido){
    return (pedido.estado && pedido.estado.estado && pedido.estado.estado == "CERRADO") || (pedido.estado && pedido.estado.estado && pedido.estado.estado == "CANCELADO");
}
Pedido.getEstado = function(pedido){
    if(Pedido.isCerrado(pedido)){               return "CERRADO";}
    if(Pedido.isListo(pedido)){                 return "LISTO";}
    if(!Pedido.getPendienteCobrar(pedido)){     return "COBRADO";}
    if(Pedido.getCobrado(pedido)){              return "COBRADO_PARCIAL"}
    if(!Pedido.getPendienteFacturar(pedido)){   return "FACTURADO";}
    if(Pedido.getFacturado(pedido)){            return "FACTURADO_PARCIAL"}
    return "CREADO";
}
Pedido.getColorEstado = function(pedido){
    switch(Pedido.getEstado(pedido)){
        case 'CERRADO':
            return "grey";
        case 'LISTO':
        case 'COBRADO':
            return "green";
        case 'COBRADO_PARCIAL':
        case 'FACTURADO':
        case 'FACTURADO_PARCIAL':
            return "orange";
    }
    return "white";
}

export default Pedido;