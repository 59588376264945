<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title sliding>{{$t('facturas.generar') }}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Generar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      @save="onSave"
      @ready="formReady"
      :beforeSave="beforeSave"
      :readonly="readonly"
      :dbAdapter="Factura"
      :defaultData="{entidadId:'DigitalTPM', fechaEmision:new Date(), fechaVencimiento:new Date((new Date()).getTime() + 30*24*60*60*1000), comentarios: [{comentario: 'Forma de pago por transferencia IBAN: ES06 0049 3377 1824 1400 0585'}], facturaItem: [{tipoIva:21}], pedidoAsociado: {}}"
    >
      <f7-row class="padding-vertical-half padding-right">
        <f7-col width="100" medium="75">
        </f7-col>
      </f7-row>
      <f7-block-title>
        {{$t('facturas.factura')}}
      </f7-block-title>
      <f7-card class="padding-vertical-half padding-right" style="z-index: 10">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="100">
              <ul>
                <f7-row>
                  <r-autocomplete
                    ref="cliente"
                    class="col-25"
                    :dbAdapter="Cliente"
                    floating-label
                    :label="$t('facturas.cliente')"
                    :form="rform"
                    name="clienteId"
                    idProp="id"
                    labelProp="nombre"
                  ></r-autocomplete>
                  <r-autocomplete
                    ref="pedido"
                    class="col-25"
                    :dbAdapter="Pedido"
                    :filter="filterPedido"
                    floating-label
                    :label="$t('facturas.pedido')"
                    :form="rform"
                    name="pedidoAsociadoId"
                    idProp="id"
                    :shownFields="['clienteId']"
                    labelProp="name"
                    :readonly="rform.readonly || !rform.formData.clienteId"
                    :source="sourcePedidos"
                  ></r-autocomplete>
                  <r-input
                    class="col-25"
                    floating-label
                    :label="$t('facturas.fecha_emision')"
                    :form="rform"
                    type="datepicker"
                    name="fechaEmision"
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                  ></r-input>
                  <r-input
                    class="col-25"
                    floating-label
                    :label="$t('facturas.fecha_vencimiento')"
                    :form="rform"
                    type="datepicker"
                    name="fechaVencimiento"
                    :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                  ></r-input>
                </f7-row>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <f7-block-title z-index>
        {{$t('facturas.comentarios')}} <f7-link v-if="!rform.readonly && rform.formData && rform.formData.comentarios" @click="rform.formData.comentarios.push({})">{{$t('facturas.add')}}</f7-link>
      </f7-block-title>
      <f7-card class="padding-vertical-half padding-right">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="100">
              <ul>
                <f7-row v-for="(comentario, index) in rform.formData.comentarios" :key="'comentario-'+index" style="position: relative">
                  <r-input
                    class="col-100"
                    floating-label
                    :form="rform"
                    :name="'comentarios.'+index+'.comentario'"
                  ></r-input>
                  <f7-link
                    v-if="!rform.readonly && rform.formData.comentarios.length > 1"
                    @click="$delete(rform.formData.comentarios,index)"
                    class="margin-half"
                    style="position:absolute;right:0;top:0;"
                    icon-f7="trash"
                  ></f7-link>
                </f7-row>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <f7-block-title>
        {{$t('facturas.lineas')}} <f7-link v-if="!rform.readonly && rform.formData && rform.formData.facturaItem" @click="rform.formData.facturaItem.push({tipoIva: 21, precioUnitario: precio})">{{$t('facturas.add')}}</f7-link>
      </f7-block-title>
      <f7-card class="padding-vertical-half padding-right" v-for="(item, index) in rform.formData.facturaItem" :key="'linea-'+index" style="position: relative">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="100">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <r-input
                      class="col-100 medium-100"
                      floating-label
                      :form="rform"
                      :name="'facturaItem.'+index+'.concepto'"
                      :label="$t('facturas.concepto')"
                    ></r-input>
                  </f7-col>
                  <f7-col width="100" medium="33">
                    <r-input
                      class="col-100 medium-33"
                      floating-label
                      :form="rform"
                      :name="'facturaItem.'+index+'.cantidad'"
                      :label="$t('facturas.cantidad')"
                    ></r-input>
                  </f7-col>
                  <f7-col width="100" medium="33">
                    <r-input
                      class="col-100 medium-33"
                      floating-label
                      :form="rform"
                      type="number"
                      step="0.01"
                      :name="'facturaItem.'+index+'.precioUnitario'"
                      :label="$t('facturas.precio_unitario')"
                    ></r-input>
                  </f7-col>
                  <f7-col width="100" medium="33">
                    <r-input
                      class="col-100 medium-33"
                      floating-label
                      :form="rform"
                      :name="'facturaItem.'+index+'.tipoIva'"
                      :label="$t('facturas.tipoIva')"
                    ></r-input>
                  </f7-col>
                  <f7-link
                    v-if="!rform.readonly && rform.formData.facturaItem.length > 1"
                    @click="$delete(rform.formData.facturaItem,index)"
                    class="margin-half"
                    style="position:absolute;right:0;top:0;"
                    icon-f7="trash"
                  ></f7-link>
                </f7-row>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Factura from "./../../js/db/Factura.js";
import Pedido from "./../../js/db/Pedido.js";
import Cliente from "./../../js/db/Cliente.js";

export default {
  components: {
    rForm,
    rInput,
    rAutocomplete,
  },
  data: function() {
    var self = this;
    return {
      Factura,
      Pedido,
      Cliente,
      readonly: !this.editable,
      facturaActual: {},
      precio: 0
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    filterPedido: function(){
      var self = this;
      if(self.facturaActual.clienteId){
        return { clienteId: self.facturaActual.clienteId};
      }
      return {};
    }
  },
  watch: {
    'facturaActual.clienteId': {
      handler: function(newCliente){
        var self = this;
        if (self.$refs.pedido) {
          self.$refs.pedido.setValue("");
        }
        if (newCliente && self.$refs.cliente) {
          let clienteItem = self.$refs.cliente.selected;
          self.precio = clienteItem.precioHabitual; 
          for (let i = 0; i < self.facturaActual.facturaItem.length; i++) {
            self.$set(self.facturaActual.facturaItem[i], "precioUnitario", self.precio );
          }
        }
      }
    }
  },

  beforeDestroy() {},
  methods: {
    sourcePedidos: function(self) {
      const res = function(query, render) {
        var results = [];
        self.items = self.initialItems;
        if (query.length >= self.minLength) {
          for (var i = 0; i < self.items.length; i++) {
            var txtValue = self.items[i][self.searchProp];
            if (self.prefixProp && self.items[i][self.prefixProp]) {
              txtValue = self.items[i][self.prefixProp] + ' - ' + self.items[i][self.searchProp];
            }
            if (
              txtValue &&
              !Pedido.isFinalizado(self.items[i]) &&
              txtValue.toLowerCase().indexOf(query.toLowerCase()) >= 0 &&
              self.showItem(self.items[i]) && 
              (!self.filter.clienteId || self.items[i].clienteId == self.filter.clienteId)
            ) {
              self.items[i]['txtValue'] = txtValue;
              results.push(self.items[i]);
            }
          }
        }
        render(results);
      };
      return res;
    },
    beforeSave: function(data, resolve, reject) {
      var formData = Object.assign({}, data);

      formData.comentarios = formData.comentarios.filter((comentario) => comentario.comentario);
      resolve(formData);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("facturas.facturas_generadas"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
      router.navigate(`/facturas/view/${res.id}/`,{reloadCurrent:true});
    },
    formReady: function(res) {
      var self = this;
      self.facturaActual = res;
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>