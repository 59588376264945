var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-popup',{ref:"popupAsociarPedido",staticClass:"popup-asociar-pedido",attrs:{"swipe-to-close":""},on:{"popup:closed":function($event){return _vm.$refs.rformAsociarPedido.clear();}}},[_c('f7-page',[_c('f7-navbar',{attrs:{"title":_vm.$t('facturas.asociar_pedido')}},[_c('f7-nav-right',[_c('f7-link',{attrs:{"popup-close":""}},[_vm._v(_vm._s(_vm.$t('cerrar')))])],1)],1),_vm._v(" "),_c('f7-card',{staticClass:"elevation-3 padding-vertical-half padding-right"},[_c('r-form',{ref:"rformAsociarPedido",attrs:{"itemId":_vm.factura?_vm.factura.id:null,"readonly":false,"dbAdapter":_vm.Factura},scopedSlots:_vm._u([{key:"default",fn:function({rform}){return [_c('f7-card-content',[_c('f7-list',{staticClass:"list-form"},[_c('ul',[_c('f7-row',[_c('f7-col',{attrs:{"width":"100","medium":"100"}},[_c('r-autocomplete',{ref:"pedido",staticClass:"padding-vertical",attrs:{"dbAdapter":_vm.Pedido,"filter":_vm.filterPedido,"floating-label":"","label":_vm.$t('facturas.pedido'),"form":rform,"name":"pedidoAsociadoId","idProp":"id","labelProp":"name","source":function(self) {
                  return function(query, render) {
                    var results = [];
                    for (var i = 0; _vm.i < self.items.length; _vm.i++) {
                        if (!_vm.Pedido.isFinalizado(self.items[_vm.i])
                          && self.items[_vm.i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0
                          && (!self.filter.clienteId || self.items[_vm.i].clienteId == self.filter.clienteId)) {
                            self.items[_vm.i]['txtValue'] = self.items[_vm.i].name;
                            results.push(self.items[_vm.i]);
                          }
                    }
                    render(results);
                  };
                }}})],1)],1)],1)])],1)]}}])}),_vm._v(" "),_c('f7-button',{staticClass:"display-flex margin-left",attrs:{"fill":"","icon-f7":"floppy_disk","text":_vm.$t('guardar')},on:{"click":_vm.asociarFactura}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }