import Entidades from './entidades.vue';
import EntidadForm from './entidad-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/entidades/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('entidades', ['ADMIN'], Entidades)
    },
    {
        path: '/entidades/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('entidades/new', ['ADMIN'], EntidadForm)
    },
    {
        path: '/entidades/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('entidades/edit', ['ADMIN'], EntidadForm)
    },
    {
        path: '/entidades/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('entidades/view', ['ADMIN'], EntidadForm, { editable: false })
    },
];
export default routes;