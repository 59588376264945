import DatabaseModel from './DatabaseModel.js';

var Documento = Object.create(DatabaseModel);

Documento.model = 'documento';

Documento.download = function(documento) {
    var self = this;
    self.getDocumento(documento).then(function(data){
        var blob = new Blob([data]);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, documento.nombre + '.' + documento.extension);
        } else {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = documento.nombre + '.' + documento.extension;
            link.target = '_blank';
            link.click();
            URL.revokeObjectURL(link.href);
        }
    });
}

Documento.getDocumento = function(documento) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_contabilidad + self.prefix + self.model + '/download',
            method: 'POST',
            contentType: 'application/json',
            crossDomain: true,
            xhrFields: {'responseType': 'arraybuffer'},
            data: documento
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

Documento.upload = function(files) {
    var self = this;
    var data = new FormData();
    data.append("files", files[0]);
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_contabilidad + self.prefix + self.model + '/upload',
            method: 'POST',
            crossDomain: true,
            dataType: 'json',
            data: data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}
Documento.uploadData = function(fileData) {
    const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
        
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
        
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
            }
        
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }
      
    var file = b64toBlob([fileData.base64.split(',')[1]]);
    var filename = `${fileData.nombre}.${fileData.extension}`;
    var formData = new FormData();
    formData.append("file", file, filename);
    return Documento.upload([formData.get("file")]);
}

export default Documento;