<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('clientes.clientes.clientes')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Cliente"
        :tableMode="true"
        :tableCode="'CLIENTE'"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :massiveActions="true"
        :controlsWidth="180"
      >
        <template v-slot:tableLinks>
          <a class="link" href="/clientes/new/">{{$t("clientes.cliente_form.nuevo_cliente")}}</a>
        </template>

        <template v-slot:optionsColumn="{item}">
          <f7-link
            icon-f7="eye"
            color="green"
            :href="`/clientes/view/${item.id}/`"
            v-tooltip="'Ver Cliente'"
          ></f7-link>
          <f7-link
            icon-f7="square_pencil"
            color="blue"
            :href="`/clientes/edit/${item.id}/`"
            v-tooltip="'Editar Cliente'"
          ></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>
  </f7-page>
</template>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import rForm from "./../../components/rForm.vue";
import Cliente from "./../../js/db/Cliente.js";
import Utils from "./../../js/Utils.js";
import rInput from "./../../components/rInput.vue";

export default {
  components: {
    rDynamicList,
    rForm,
    rInput
  },
  data() {
    return {
      Cliente,
      Utils
    };
  },
  computed: {},
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }, 
  methods: {
    reload: function(){
      var self = this;
      Cliente.resetCache();
      self.$refs.rlist.refresh();
    }
  }
};
</script>