import MainMenu from '../pages/main-menu.vue';
import Dashboard from '../pages/dashboard.vue';
import NotFoundPage from '../pages/404.vue';
import RoutesUtils from './../js/RoutesUtils.js';

var routesMain = [{
        path: '/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('dashboard', ['ADMIN'], Dashboard)
    },
    {
        path: '(.*)',
        component: NotFoundPage,
    },
];


var routesLeft = [{
    path: '/main-menu/',
    beforeEnter: function(routeTo, routeFrom, resolve, reject) {
        if (!RoutesUtils.checkAuth()) {
            reject();
        } else {
            resolve();
        }
    },
    component: MainMenu
}];
export { routesMain, routesLeft };