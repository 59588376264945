<template>
  <f7-page :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('facturas.facturas')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Factura"
        :tableCode="'FACTURA'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['estado.estado','pedidoAsociado']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="180"
      >
        <template v-slot:tableLinks>
          <a class="link" href="/facturas/new/">{{$t("facturas.generar")}}</a>
        </template>
        <template v-slot:optionsColumn="{item}">
          <f7-link
            icon-f7="eye"
            color="green"
            :href="`/facturas/view/${item.id}/`"
            v-tooltip="'Ver Factura'"
          ></f7-link>
          <f7-link
            v-if="item.estado.estado == 'CREADA'"
            icon-f7="square_pencil"
            color="blue"
            :href="`/facturas/edit/${item.id}/`"
            v-tooltip="'Editar Factura'"
          ></f7-link>
<!--           <f7-link
            v-if="item.estado.estado != 'RECHAZADA' && item.estado.estado != 'CANCELADA'"
            icon-f7="paperplane_fill"
            color="green"
            @click="send(item)"
            v-tooltip="'Enviar Factura'"
          ></f7-link> -->
          <f7-link
            v-if="item.estado.estado != 'RECHAZADA' && item.estado.estado != 'CANCELADA'"
            icon-f7="cloud_download_fill"
            color="green"
            @click.prevent="downloadFactura(item)"
            v-tooltip="'Descargar Factura'"
          ></f7-link>
          <f7-link 
            v-if="item.estado.estado == 'CREADA' || item.estado.estado == 'ENVIADA'" 
            icon-f7="arrow_2_circlepath_circle" 
            color="orange" 
            popup-open=".popup-cambio-estado" 
            v-tooltip="'Cambiar estado'"
            @click="facturaSelected = item"
          ></f7-link>
          <f7-link 
            v-if="!item.pedidoAsociado"
            icon-f7="arrow_2_squarepath" 
            color="orange" 
            popup-open=".popup-asociar-pedido" 
            v-tooltip="$t('facturas.asociar_pedido')"
            @click="facturaSelected = item"
          ></f7-link>
          <f7-link
            v-if="item.estado.estado != 'PAGADA' && item.estado.estado != 'RECHAZADA' && item.estado.estado != 'CANCELADA'"
            icon-f7="clear"
            color="red"
            @click="facturaSelected = item"
            popup-open=".popup-cancel-factura"
            v-tooltip="'Cancelar Factura'"
          ></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>
    <f7-popup ref="popupCambioEstado" class="popup-cambio-estado" @popup:closed="$refs.rformCambioEstado.clear();" swipe-to-close>
      <f7-page>
        <f7-navbar title="Cambiar Estado de Factura">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <r-form
        ref="rformCambioEstado"
        v-slot="{rform}"
        :itemId="facturaSelected?facturaSelected.id:null"
        :readonly="false"
        :dbAdapter="Factura"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <f7-col width="100" medium="100">
                  <r-input
                    floating-label
                    :label="$t('facturas.estado')"
                    :form="rform"
                    type="select"
                    name="estado"
                  >
                    <option value></option>
                    <option value="CREADA" v-if="facturaSelected && facturaSelected.estado && facturaSelected.estado.estado == 'ENVIADA'">Creada</option>
                    <option value="ENVIADA" v-if="facturaSelected && facturaSelected.estado && facturaSelected.estado.estado == 'CREADA'">Enviada</option>
                    <option value="PAGADA" v-if="facturaSelected && facturaSelected.estado && (facturaSelected.estado.estado == 'CREADA' || facturaSelected.estado.estado == 'ENVIADA')">Pagada</option>
                    <option value="RECHAZADA" v-if="facturaSelected && facturaSelected.estado && (facturaSelected.estado.estado == 'CREADA' || facturaSelected.estado.estado == 'ENVIADA')">Rechazada</option>
                  </r-input>
                  <r-input
                      floating-label
                      label="Comentario"
                      :form="rform"
                      type="textarea"
                      name="comentario"
                    ></r-input>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </r-form>
      <f7-button
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex margin-left"
          @click="cambiarEstado"
        ></f7-button>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
    <f7-popup
      ref="popupCancelFactura"
      class="popup-cancel-factura"
      swipe-to-close
    >
      <f7-page>
        <f7-navbar title="Cancelar Factura">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card class="elevation-3 padding-vertical-half padding-right">
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <input
                      maxlength="256"
                      floating-label
                      :placeholder="$t('facturas.motivo_cancel')"
                      type="text"
                      name="motivoCancel"
                      ref="motivoCancel"
                      class="padding-left"
                    />
                  </f7-col>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-button
            fill
            icon-f7="clear"
            text="Cancelar"
            class="display-flex margin-left"
            color="red"
            @click="cancelFactura()"
          ></f7-button>
        </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
    <factura-modal-pedido 
      :factura="facturaSelected"
      @save="reload()"
    ></factura-modal-pedido>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import Factura from "./../../js/db/Factura.js";
import rForm from "./../../components/rForm.vue";
import Utils from "./../../js/Utils.js";
import facturaModalPedido from "./factura-modal-pedido.vue";
import rInput from "./../../components/rInput.vue";

export default {
  components: {
    rDynamicList,
    facturaModalPedido,
    rForm,
    rInput,
  },
  data() {
    return {
      Factura,
      Utils,
      facturaSelected: null,
    };
  },
  computed: {},
  methods: {
    cancelFactura: function() {
      var self = this;
      var app = self.$f7;
      var id = self.facturaSelected.id;
      var mensaje = self.$refs.motivoCancel.value;
      if (!mensaje || mensaje == "") {
        app.dialog.alert("Debe introducir un motivo.");
        return;
      }
      self.$f7.dialog.confirm(
        "¿Esta seguro que desea cancelar la factura seleccionada?",
        "Cancelar",
        function() {
          app.preloader.show();
          Factura.delete({ itemId: id, data: { comentario: mensaje } })
            .then(function(res) {
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: "Factura cancelada",
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$refs.motivoCancel.value = "";
                if (self.$refs.popupCancelFactura) {
                  self.$refs.popupCancelFactura.close(true);
                }
                self.reload();
              }
            })
            .catch(function(error) {
              app.dialog.alert(error.message);
            })
            .finally(_ => app.preloader.hide());
        }
      );
    },
    showCheckInList: function(item) {
      // return item.estado && item.estado.estado == "CREADO"; 
      return true; 
    },
    send(item) {
      const self = this;
      const app = self.$f7;
      app.preloader.show();
      Factura.send({ itemId: item.id }).then(_ => self.reload()).finally(_ => app.preloader.hide());
    }, 
    downloadFactura(item){
      var self = this;
      var app = self.$f7;
      app.preloader.show();
      Factura.downloadFactura(item).finally(_=>app.preloader.hide());
    },
    cambiarEstado: function(e) {
      var self = this;
      var app = self.$f7;
      var data = self.$refs.rformCambioEstado.formData;
      app.dialog.confirm(
        "¿Esta seguro de querer cambiar el estado de la factura " + self.facturaSelected.numero + "?",
        "Cambio de estado",
        function() {
          app.preloader.show();
          Factura.cambioEstado(self.facturaSelected.id, data).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: 'Cambio de estado realizado',
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
                self.$refs.rformCambioEstado.clear();
                if (self.$refs.popupCambioEstado) {
                  self.$refs.popupCambioEstado.close(true);
                }
                self.reload();
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          }).finally( _ => app.preloader.hide());
        }
      );
    },
    reload: function(){
      var self = this;
      Factura.resetCache();
      self.$refs.rlist.refresh();
    },
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    /*app.dataTable.create({
      el: self.$refs.datatable
    });*/
  }
};
</script>