<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title sliding>{{$t('perfil.perfil')}}</f7-nav-title>
      <f7-nav-right>
      </f7-nav-right>
    </f7-navbar>
    <f7-block-title>
      Contraseña
    </f7-block-title>
      <f7-card class="padding-vertical-half padding-right" style="z-index: 2;">
        <f7-card-content>
          <f7-row>
            <f7-button
              fill
              icon-f7="lock"
              text="Cambiar Contraseña"
              class="display-flex"
              popup-open=".popup-cambio-pass"
              width="100"
              medium="30"
            ></f7-button>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <f7-popup ref="popupCambioPass" class="popup-cambio-pass" swipe-to-close>
        <f7-page>
          <f7-navbar title="Cambiar Contraseña">
            <f7-nav-right>
              <f7-link popup-close>Close</f7-link>
            </f7-nav-right>
          </f7-navbar>

          <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card
          class="elevation-3 padding-vertical-half padding-right"
        >
        <f7-list form>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-floating-label">{{$t('perfil.actual_pass')}}</div>
              <div class="item-input-wrap">
                <input type="password" name="oldPassword" placeholder />
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-floating-label">{{$t('perfil.new_pass')}}</div>
              <div class="item-input-wrap">
                <input type="password" name="newPassword" placeholder />
              </div>
            </div>
          </li>
          <li class="item-content item-input">
            <div class="item-inner">
              <div class="item-title item-floating-label">{{$t('perfil.repeat_new_pass')}}</div>
              <div class="item-input-wrap">
                <input type="password" name="repeatPassword" placeholder />
              </div>
            </div>
          </li>
        </f7-list>
        <f7-button
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-flex"
            @click="saveCambioPass"
          ></f7-button>
        </f7-card>
          <!-- </div> -->
        </f7-page>
      </f7-popup>
      <div v-roles="{route: $f7route.url, roles: ['CLIENTE']}"
         v-for="(cliente,index) in clientes" :key="'cliente-'+index"
         :style="'z-index:'+ (clientes.length - index)"
        >
        <r-form
          ref="rform"
          v-slot="{rform}"
          :itemId="cliente.id"
          @save="onSave"
          :beforeSave="beforeSave"
          :dbAdapter="Cliente"
          :defaultData="{clienteContactos:[]}"
        >
        <f7-block-title>
          {{cliente.nombre}} - Datos para Formulario 360º
        <f7-link @click="rform.formData.clienteContactos.push({});">Añadir</f7-link>
          <f7-button
            fill
            icon-f7="floppy_disk"
            text="Guardar"
            class="display-inline-flex btn-cliente"
            @click="rform.saveItem()"
          ></f7-button>
        </f7-block-title>
          <f7-card 
            v-for="(clienteContacto,index2) in rform.formData.clienteContactos" :key="'clienteContacto-'+index2"
            class="padding-vertical-half padding-right"
            :style="'z-index:'+ (clientes.length - index) + '' + (rform.formData.clienteContactos.length - index2)">
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row>
                    <r-autocomplete
                      class="col-33"
                      :dbAdapter="ClienteContacto"
                      floating-label
                      :label="$t('perfil.cliente_contacto')"
                      :form="rform"
                      :name="'clienteContactos.' + index2 + '.clienteContacto'"
                      labelProp="nombre"
                      idProp="valor"
                      :source="function(self) {
                        return function(query, render) {
                          var results = [];
                          var clienteContactos = [];
                          if (rform.formData.clienteContactos) {
                            for (var index in rform.formData.clienteContactos) {
                              var cc = rform.formData.clienteContactos[index];
                              if (cc.clienteContacto) {
                                clienteContactos.push(cc.clienteContacto)
                              }
                            }
                          }
                          for (var i = 0; i < self.items.length; i++) {
                            if (
                              ( self.items[i][self.searchProp].toLowerCase().indexOf(query.toLowerCase()) >= 0 ) && 
                              !clienteContactos.includes(self.items[i]['valor']))
                              results.push(self.items[i]);
                          }
                          render(results);
                        };
                      }"
                    ></r-autocomplete>
                    <r-input
                      class="col-33"
                      floating-label
                      :label="$t('perfil.nombre')"
                      :form="rform"
                      :name="'clienteContactos.' + index2 + '.nombre'"
                    ></r-input>
                    <r-files 
                      class="col-33" 
                      :maxFiles="1"
                      :label="$t('perfil.firma')" 
                      :form="rform" 
                      :name="'clienteContactos.' + index2 + '.firma'" 
                    />
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
            <f7-link
              @click="$delete(rform.formData.clienteContactos,index2);"
              class="margin-half"
              style="position:absolute;right:0;top:0;"
              icon-f7="trash"
            ></f7-link>
          </f7-card>
        </r-form>
      </div>
  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
.btn-cliente {
  width: 200px;
  float: right;
}
</style>
<script>
import Vue from "vue";
import Usuario from "./../../js/db/Usuario.js";
import ClienteContacto from "./../../js/db/ClienteContacto.js";
import Cliente from '../../js/db/Cliente';
import rForm from "./../../components/rForm.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rInput from "./../../components/rInput.vue";
import rFiles from "./../../components/rFiles.vue";


export default {
  components: {
    rForm,
    rAutocomplete,
    rInput,
    rFiles
  },
  data: function() {
    var self = this;
    return {
      Usuario,
      Cliente,
      ClienteContacto,
      clientes: []
    };
  },
  props: {
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var app = self.$f7;
      data.clienteContactos.forEach((cc,i) => self.$set(data.clienteContactos[i], 'firma', Array.isArray(cc.firma) ? cc.firma[0] : cc.firma));
      resolve(data);
    },
    getUser: function() {
      var user = localStorage.getItem("user");
      if (typeof user != "undefined" && user) {
        var userObj = JSON.parse(user);
        return userObj;
      }
      return null;
    },
    saveCambioPass: function(e) {
      var self = this;
      var app = self.$f7;
      var $el = self.$$(self.$refs.popupCambioPass.$el);
      var data = app.form.convertToData($el.find("form"));
      if (!data.oldPassword || data.oldPassword == '') {
        app.dialog.alert("Introduzca la contraseña actual.");
        return;
      }
      if (!data.newPassword || data.newPassword == '') {
        app.dialog.alert("Introduzca la nueva contraseña.");
        return;
      }
      if (!data.repeatPassword || data.repeatPassword == '') {
        app.dialog.alert("Introduzca la repetición de la nueva contraseña.");
        return;
      }
      if (data.newPassword != data.repeatPassword) {
        app.dialog.alert("Las nuevas contraseñas no coinciden");
        return;
      }
      app.preloader.show();
      Usuario.cambiarPass(data)
        .then(function(res) {
          app.preloader.hide();
          app.toast
            .create({
              text: 'Contraseña Cambiada',
              position: "center",
              closeTimeout: 2000
            })
            .open();
          self.$refs.popupCambioPass.close(true);
        })
        .catch(function(e) {
          app.preloader.hide();
          app.toast
            .create({
              text: e.error_description,
              position: "center",
              closeTimeout: 2000
            })
            .open();
        });
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      app.toast
        .create({
          text: self.$t("clientes.cliente_form.cliente_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    var user = self.getUser();
    if (user && user.roles && user.roles.includes("CLIENTE")) {
      app.preloader.show();
      Cliente.getList({filter: {soloClientesDirectos:true}})
        .then(function(res) {
          self.clientes = res.list.filter(c =>
            c.configuraciones.filter(conf=>conf.tipoConfiguracion == 'PROCEDIMIENTO' && conf.valor == 'PROCEDIMIENTO_2').length > 0
          );
        })
        .catch(function(e) {
          if (typeof e == "string") e = { error: e };
          let error = e.error || e.error_description;
          app.toast
            .create({
              text: error,
              position: "center",
              closeTimeout: 2000
            })
            .open();
        })
        .finally(function() { app.preloader.hide();});
    }

  }
};
</script>