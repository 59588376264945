<template>
  <f7-page name="home" :page-content="false">
    <!-- Top Navbar -->
    <f7-navbar :sliding="false">
      <f7-nav-left></f7-nav-left>
      <f7-nav-title sliding>Panel de control</f7-nav-title>
      <f7-nav-right></f7-nav-right>
    </f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <f7-list class="list-form">
        <f7-row>
          <f7-button
            fill
            v-roles="{route: $f7route.url, roles: ['ADMIN']}"
            icon-f7="doc_text_fill"
            text="Crear Nueva Cita"
            class="display-flex margin col-25"
            href="/pedidos/new/"
          ></f7-button>
        </f7-row>
        <f7-card v-for="(item,index) in list" :key="'lista-'+index"  >
          <f7-card-content>
            <!-- <f7-icon f7="gear_alt_fill">{{item.imagen}}</f7-icon> -->
            <i class="f7-icons"
              :class="'text-color-' + item.color"
            >{{item.imagen}}</i>
            {{item.nombre}}: <span :class="'badge color-' + item.color ">{{item.valor}}</span>
          </f7-card-content>
        </f7-card>
      </f7-list>
    </f7-page-content>
  </f7-page>
</template>
<style scoped>
.badge {
  top: -2px;
}
</style>
<script>
import rList from "./../components/rList.vue";
import Dashboard from "./../js/db/Dashboard.js";

export default {
  components: {},
  data() {
    return {
      Dashboard,
      list: []
    };
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
    Dashboard.getList().then(function(res) {
      self.$set(self, 'list', res.list);
    })
  }
};
</script>