import DatabaseModel from './DatabaseModel.js';
import Pedido from './Pedido.js';

var Factura = Object.create(DatabaseModel);

Factura.model = 'facturas';

Factura.delete = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server_contabilidad + self.prefix + self.model + '/' + params.itemId + '/cancel',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    }).then(function(res){
        Pedido.resetCache();
        return res;
    });
};

Factura.send = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server_contabilidad + self.prefix + self.model + '/' + params.itemId + '/send',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    }).then(function(res){
        Pedido.resetCache();
        return res;
    });
};

Factura.downloadFactura = function(item) {
    var self = this;
    return self.download(
        self.server_contabilidad + self.prefix + self.model + '/' + item.id + '/pdf', 
        'Factura ' + item.numero + '.pdf');
}

Factura.cambioEstado = function(id, data) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server_contabilidad + self.prefix + self.model + '/' + id + '/cambioestado',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    }).then(function(res){
        Pedido.resetCache();
        return res;
    });
}

Factura.asociarPedido = function(idFactura, idPedido) {
    var self = this;
    var data = { pedidoAsociado: { id: idPedido } };
    return self.sendRequest({
        url: self.server_contabilidad + self.prefix + self.model + '/' + idFactura + '/asociarpedido',
        method: 'PUT',
        data: data
    }, false).then(function(res){
        Pedido.resetCache();
        return res;
    });
}

Factura.isFinalizado = function(factura) {
    return ["RECHAZADA", "PAGADA", "CANCELADA"].indexOf(factura.estado.estado) >= 0;
}
Factura.isFacturado = function(factura) {
    return ["ENVIADA", "PAGADA"].indexOf(factura.estado.estado) >= 0;
}
Factura.isCobrado = function(factura) {
    return "PAGADA" == factura.estado.estado;
}
Factura.getSubtotal = function(factura) {
    if(!factura.facturaItem){
        return 0;
    }
    return factura.facturaItem.reduce((t, {cantidad, precioUnitario}) => t + parseInt(cantidad) * precioUnitario, 0);
}
Factura.getTotalIva = function(factura) {
    if(!factura.facturaItem){
        return 0;
    }
    return factura.facturaItem.reduce((t, {cantidad, precioUnitario, tipoIva}) => t + parseInt(cantidad) * precioUnitario * tipoIva / 100, 0);
}
Factura.getTotalFactura = function(factura) {
    if(!factura.facturaItem){
        return 0;
    }
    return factura.facturaItem.reduce((t, {cantidad, precioUnitario, tipoIva}) => t + parseInt(cantidad) * precioUnitario * (1 + tipoIva / 100), 0);
}

export default Factura;