<template>
  <f7-row>
    <r-input
      class="col-25"
      floating-label
      :label="$t('componentes.direccion.codigo_postal')"
      :form="form"
      type="text"
      :name="name + '.cp'"
    ></r-input>
    <r-input
      class="col-75"
      floating-label
      :label="$t('componentes.direccion.direccion')"
      :form="form"
      type="text"
      :name="name + '.direccion'"
    ></r-input>
    <r-input
      class="col-33"
      floating-label
      :label="$t('componentes.direccion.poblacion')"
      :form="form"
      type="text"
      :name="name + '.poblacion'"
    ></r-input>
    <r-input
      class="col-33"
      floating-label
      :label="$t('componentes.direccion.provincia')"
      :form="form"
      type="text"
      :name="name + '.provincia'"
    ></r-input>
    <r-input
      class="col-33"
      floating-label
      :label="$t('componentes.direccion.pais')"
      :form="form"
      type="text"
      :name="name + '.pais'"
    ></r-input>
  </f7-row>
</template>
<style>
  div.list{
    list-style: none;
  }
  select[multiple] {
    height: 100px;
    margin-top: 10px; 
  }
</style>
<script>
import rInput from "./rInput.vue";
export default {
  name: "r-direccion",
  components: {
    rInput
  },
  data() {
    return {
      self: this
    };
  },
  methods: {},
  props: {
    name: {
      required: true,
      type: String
    },
    form: {
      required: true
    }
  },
  mounted() {
    var $ = this.$$;
    var $el = $(this.$el);
    var self = this;
  }
};
</script>