import { render, staticRenderFns } from "./facturas.vue?vue&type=template&id=5db46291&scoped=true&"
import script from "./facturas.vue?vue&type=script&lang=js&"
export * from "./facturas.vue?vue&type=script&lang=js&"
import style0 from "./facturas.vue?vue&type=style&index=0&id=5db46291&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5db46291",
  null
  
)

export default component.exports