import DatabaseModel from './DatabaseModel.js';
import CacheService from './Cache.js';

var TablaUsuario = Object.create(DatabaseModel);

TablaUsuario.model = 'core/tablausuario';

TablaUsuario.createConsulta = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_contabilidad + self.prefix + self.model + '/consultas',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}

TablaUsuario.sharedConsulta = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_contabilidad + self.prefix + self.model + '/consulta/shared',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}

TablaUsuario.deleteConsulta = function(params) {
    var self = this;
    params = params || {};
    var params = {
        url: self.server_contabilidad + self.prefix + self.model + '/consultas/delete',
        method: 'POST',
        data: params.data
    };
    return self.sendRequest(params, false).then(function(res) {
        CacheService.clearCache(self.model);
        return res;
    });
}

export default TablaUsuario;