<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('pedidos.pedido_form.editar_pedido')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('pedidos.pedido_form.pedido')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('pedidos.pedido_form.nuevo_pedido')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      :defaultData="{lineas:[], comentarios:[], ofertasId:[], documentos:[], facturasAsociadas: []}"
      :readonly="readonly"
      :dbAdapter="Pedido"
      :beforeSave="beforeSave"
      @ready="formReady"
      @loadItem="loadItem"
    >
      <!-- <f7-row class="padding-vertical-half padding-right">
        <f7-col width="100" medium="75">
        </f7-col>
        <f7-col width="100" medium="25">
          <f7-button
            v-roles="{route: $f7route.url, roles: ['ADMIN']}"
            v-if="readonly && rform.formData.estado && rform.formData.estado.estado == 'PDTE_CONFIRMAR'" 
            fill
            icon-f7="doc_text_fill"
            text="Preparar Cita"
            class="display-flex"
            color="green"
            popup-open=".popup-prepare-pedido"
          ></f7-button>
        </f7-col>
      </f7-row> -->
      <f7-block-title>{{$t('pedidos.pedido_form.pedido')}}</f7-block-title>
      <f7-card class="padding-vertical-half padding-right" :style="'z-index:'+(rform.formData.lineas.length + 10)">
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row no-gap>
                <f7-col width="100" medium="66">
                  <f7-row no-gap class="padding-right">
                    <f7-col width="100" medium="66">
                      <r-autocomplete
                        ref="cliente"
                        :dbAdapter="Cliente"
                        floating-label
                        :label="$t('pedidos.pedido_form.cliente')"
                        :form="rform"
                        name="cliente"
                        nameId="clienteId"
                        labelProp="nombre"
                        :readonly="rform.formData.id && rform.formData.id != ''"
                        @loaded="if($event.length==1) $refs.cliente.setValue($event[0]);"
                      ></r-autocomplete>
                    </f7-col>
                    <f7-col width="100" medium="33">
                      <r-input
                        v-if="rform.formData.code != null"
                        floating-label
                        :label="$t('pedidos.pedido_form.codigo')"
                        :form="rform"
                        type="text"
                        name="code"
                        :readonly="true"
                      ></r-input>
                    </f7-col>
                  </f7-row>
                  <f7-row class="padding-right">
                    <f7-col width="100" medium="66">
                      <r-input
                        floating-label
                        :label="$t('pedidos.pedido_form.concepto')"
                        :form="rform"
                        type="text"
                        name="name"
                        :readonly="rform.readonly"
                      ></r-input>
                    </f7-col>
                    <f7-col width="100" medium="33">
                      <r-input
                        floating-label
                        :label="$t('pedidos.pedido_form.codigoCliente')"
                        :form="rform"
                        type="text"
                        name="codigoCliente"
                        :readonly="rform.readonly"
                      ></r-input>
                    </f7-col>
                  </f7-row>
                  <f7-row class="padding-right">
                    <f7-col width="100" medium="33"></f7-col>
                    <f7-col width="100" medium="33">
                      <r-input
                        floating-label
                        :label="$t('pedidos.pedido_form.fecha')"
                        :form="rform"
                        type="datepicker"
                        name="fecha"
                      ></r-input>
                    </f7-col>
                    <f7-col width="100" medium="33">
                      <r-input
                        :readonly="rform.readonly"
                        floating-label
                        :label="$t('pedidos.pedido_form.precio_defecto')"
                        type="number"
                        :form="rform"
                        name="precio"
                      ></r-input>
                    </f7-col>
                  </f7-row>
                </f7-col>
                <f7-col width="100" medium="33">
                  <f7-row class="padding-right">
                    <f7-col width="100" medium="50">
                      <f7-list-input
                        :readonly="true"
                        floating-label
                        :label="$t('pedidos.pedido_form.facturado')"
                        type="number"
                        :value="facturado"
                        :outline="true"
                      ></f7-list-input>
                    </f7-col>
                    <f7-col width="100" medium="50">
                      <f7-list-input
                        :readonly="true"
                        floating-label
                        :label="$t('pedidos.pedido_form.pendiente_facturar')"
                        type="number"
                        :value="pendienteFacturar"
                        :outline="true"
                        :color-theme="clasePorPorcentaje(porcentajeFacturado)"
                      ></f7-list-input>
                    </f7-col>
                  </f7-row>
                  <f7-row class="padding-right">
                    <f7-col width="100" medium="50">
                      <f7-list-input
                        :readonly="true"
                        floating-label
                        :label="$t('pedidos.pedido_form.cobrado')"
                        type="number"
                        :value="cobrado"
                        :outline="true"
                      ></f7-list-input>
                    </f7-col>
                    <f7-col width="100" medium="50">
                      <f7-list-input
                        :readonly="true"
                        floating-label
                        :label="$t('pedidos.pedido_form.pendiente_cobrar')"
                        type="number"
                        :value="pendienteCobrar"
                        :outline="true"
                        :color-theme="clasePorPorcentaje(porcentajeCobrado)"
                      ></f7-list-input>
                    </f7-col>
                  </f7-row>
                  <f7-row class="padding-right">
                    <f7-col width="100" medium="50">
                      <f7-list-input
                        :readonly="true"
                        floating-label
                        :label="$t('pedidos.pedido_form.horas_totales')"
                        type="number"
                        :value="horasTotales"
                      ></f7-list-input>
                    </f7-col>
                    <f7-col width="100" medium="50">
                      <f7-list-input
                        :readonly="true"
                        floating-label
                        :label="$t('pedidos.pedido_form.importe_total')"
                        type="number"
                        :value="importeTotal"
                      ></f7-list-input>
                    </f7-col>
                  </f7-row>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <!-- <f7-block-title>{{$t('pedidos.pedido_form.ofertas')}} <f7-link v-if="!rform.readonly" @click="rform.formData.ofertasId.push({});$set(rform.formData, 'ofertas', rform.formData.ofertas) ">{{$t('pedidos.pedido_form.add')}}</f7-link></f7-block-title>
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
        v-for="(item,index) in rform.formData.ofertasId"
        :key="'oferta-'+index"
        :style="'z-index:'+(rform.formData.ofertasId.length - index)"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <f7-col v-if="readonly" width="100" medium="50">
                  <r-input
                    :readonly="true"
                    floating-label
                    :label="$t('pedidos.pedido_form.oferta')"
                    :form="rform"
                    :name="'oferta.'+index"
                  >
                  </r-input>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
        <f7-link
          v-if="!rform.readonly"
          @click="deleteLinea(index)"
          class="margin-half"
          style="position:absolute;right:0;top:0;"
          icon-f7="trash"
        ></f7-link>
      </f7-card> -->
      <div class="margin-horizontal">
        <f7-segmented raised class="elevation-6 width-100">
          <f7-button fill tab-link="#tab-lineas" tab-link-active>Líneas</f7-button>
          <f7-button fill tab-link="#tab-facturas-asociadas">Facturas asociadas</f7-button>
        </f7-segmented>
      </div>
      <f7-tabs animated>
        <f7-tab id="tab-lineas" tab-active>
          <f7-block-title>{{$t('pedidos.pedido_form.lineas')}} <f7-link v-if="!rform.readonly" @click="rform.formData.lineas.push({precio: rform.formData.precio, iva:21});$set(rform.formData, 'lineas', rform.formData.lineas) ">{{$t('pedidos.pedido_form.add')}}</f7-link></f7-block-title>
          <f7-block-title v-if="rform.formData.lineas.length == 0">
          <div>** {{$t('pedidos.pedido_form.no_lineas')}} **</div>
          </f7-block-title>
          <f7-card
            class="elevation-3 padding-vertical-half padding-right"
            v-for="(item,index) in rform.formData.lineas"
            :key="'linea-'+index"
            :style="'z-index:'+(rform.formData.lineas.length - index)"
          >
            <f7-card-content>
              <f7-list class="list-form">
                <ul>
                  <f7-row class="padding-right">
                    <f7-col width="100" medium="40">
                      <r-input
                        :readonly="rform.readonly"
                        floating-label
                        :label="$t('pedidos.pedido_form.concepto')"
                        :form="rform"
                        :name="'lineas.'+index+'.concepto'"
                      ></r-input>
                    </f7-col>
                    <f7-col width="100" medium="15">
                      <r-input
                        :readonly="rform.readonly"
                        floating-label
                        :label="$t('pedidos.pedido_form.horas')"
                        :form="rform"
                        type="number"
                        :name="'lineas.'+index+'.horas'"
                      ></r-input>
                    </f7-col>
                    <f7-col width="100" medium="15">
                      <r-input
                        :readonly="rform.readonly"
                        floating-label
                        :label="$t('pedidos.pedido_form.iva')"
                        :form="rform"
                        type="select"
                        :name="'lineas.'+index+'.iva'"
                      >
                        <option value="21">21%</option>
                        <option value="10">10%</option>
                        <option value="4">4%</option>
                        <option value="0">0%</option>
                      </r-input>
                    </f7-col>
                    <f7-col width="100" medium="15">
                      <r-input
                        :readonly="rform.readonly"
                        floating-label
                        :label="$t('pedidos.pedido_form.precio')"
                        :form="rform"
                        type="number"
                        :name="'lineas.'+index+'.precio'"
                      ></r-input>
                    </f7-col>
                    <f7-col width="100" medium="15">
                    <f7-list-input
                        :readonly="true"
                        floating-label
                        :label="$t('pedidos.pedido_form.precio')"
                        type="number"
                        :value="subtotal[index]"
                      ></f7-list-input>
                    </f7-col>
                  </f7-row>
                </ul>
              </f7-list>
            </f7-card-content>
            <f7-link
              v-if="!rform.readonly"
              @click="deleteLinea(index)"
              class="margin-half"
              style="position:absolute;right:0;top:0;"
              icon-f7="trash"
            ></f7-link>
          </f7-card>
        </f7-tab>
        <f7-tab id="tab-facturas-asociadas">
          <f7-block-title>{{$t('pedidos.pedido_form.facturas_asociadas')}}</f7-block-title>
          <f7-card class="elevation-3 padding-vertical-half padding-right">
            <f7-card-content>
              <r-list
                ref="facturasAsociadas"
                slot="default"
                :tableMode="true"
                :virtualListParams="{ height: 32 }"
                class="card full-height data-table"
                classContent="card-content full-height"
                :classList="['full-height']"
              >
                <template v-if="!readonly && readonly" v-slot:before="{ rlist }"><!-- TODO Habilitar cuando esté preparado-->
                  <f7-card-header>
                    <div
                      class="data-table-header"
                      v-if="Object.keys(rlist.selected).length == 0"
                    >
                      <div class="data-table-links">
                        <a class="link" href="/facturas/new/">{{$t("facturas.generar")}}</a>
                        <a class="link" href="/facturas/new/">{{$t("pedidos.pedido_form.asociar_factura")}}</a>
                      </div>
                    </div>
                    <div class="data-table-header-selected display-flex" v-else>
                      <!-- Selected table title -->
                      <div class="data-table-title-selected">
                        <span class="data-table-selected-count">{{
                          $tc(
                            "rlist.n_registros_seleccionados",
                            Object.keys(facturasAsociadas.selected).length,
                            { count: Object.keys(facturasAsociadas.selected).length }
                          )
                        }}</span>
                      </div>
                      <!-- Selected table actions -->
                      <div class="data-table-actions">
                        <!-- <f7-link icon-f7="trash"></f7-link> -->
                      </div>
                    </div>
                  </f7-card-header>
                </template>

                <template v-slot:header="{ rlist }">
                  <thead class="thead-sticky">
                    <tr>
                      <th
                        style="min-width:1em;"
                        @click="rlist.setOrder('numero')"
                        :class="rlist.sortableClasses('numero')"
                      >
                        {{ $t("facturas.numero") }}
                      </th>
                      <th
                        style="min-width:1em;"
                        @click="rlist.setOrder('estado')"
                        :class="rlist.sortableClasses('estado')"
                      >
                        {{ $t("facturas.estado") }}
                      </th>
                      <th
                        style="min-width:1em;"
                        @click="rlist.setOrder('fechaEmision')"
                        :class="rlist.sortableClasses('fechaEmision')"
                      >
                        {{ $t("facturas.fecha_emision") }}
                      </th>
                      <th
                        style="min-width:1em;"
                        @click="rlist.setOrder('fechaVencimiento')"
                        :class="rlist.sortableClasses('fechaVencimiento')"
                      >
                        {{ $t("facturas.fecha_vencimiento") }}
                      </th>
                      <th
                        style="min-width:1em;"
                        @click="rlist.setOrder('subtotal')"
                        :class="rlist.sortableClasses('subtotal')"
                      >
                        {{ $t("facturas.subtotal") }}
                      </th>
                      <th
                        style="min-width:1em;"
                        @click="rlist.setOrder('totalIva')"
                        :class="rlist.sortableClasses('totalIva')"
                      >
                        {{ $t("facturas.totalIva") }}
                      </th>
                      <th
                        style="min-width:1em;"
                        @click="rlist.setOrder('totalFactura')"
                        :class="rlist.sortableClasses('totalFactura')"
                      >
                        {{ $t("facturas.totalFactura") }}
                      </th>
                      <th
                        style="width:7em;"
                        class="label-cell text-align-center right-column-sticky"
                        v-if="false"> <!-- TODO Eliminar condición cuando se implemente -->
                        <f7-icon f7="gear_alt_fill"></f7-icon>
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:default="{ vlData }">
                  <tr v-for="item in vlData.items" :key="'factura-' + item.id" @click="navegarAFactura(item)">
                    <td class="label-cell">{{ item.numero }}</td>
                    <td v-html="getEstadoBadge(item.estado.estado)"></td>
                    <td>
                      {{
                        item.fechaEmision || "-" | dateParse | dateFormat("DD/MM/YYYY")
                      }}
                    </td>
                    <td>
                      {{
                        item.fechaVencimiento ||
                          "-" | dateParse | dateFormat("DD/MM/YYYY")
                      }}
                    </td>
                    <td>{{ item.totales.subtotal | numberFormat(2, ',', '.') }}</td>
                    <td>{{ item.totales.iva | numberFormat(2, ',', '.') }}</td>
                    <td>{{ item.totales.importeTotal | numberFormat(2, ',', '.') }}</td>
                    <td class="text-align-right right-column-sticky"
                      v-if="false"> <!-- TODO Eliminar condición cuando se implemente -->
                      <f7-link
                        icon-f7="eye"
                        color="green"
                        :href="`/facturas/view/${item.id}/`"
                        v-tooltip="'Ver Factura'"
                      ></f7-link>
                      <f7-link
                        v-if="item.estado.estado == 'CREADA'"
                        icon-f7="square_pencil"
                        color="blue"
                        :href="`/facturas/edit/${item.id}/`"
                        v-tooltip="'Editar Factura'"
                      ></f7-link>
                      <f7-link
                        v-if="item.estado.estado != 'RECHAZADA' && item.estado.estado != 'CANCELADA'"
                        icon-f7="paperplane_fill"
                        color="green"
                        @click="send(item)"
                        v-tooltip="'Enviar Factura'"
                      ></f7-link>
                      <f7-link
                        v-if="item.estado.estado != 'RECHAZADA' && item.estado.estado != 'CANCELADA'"
                        icon-f7="cloud_download_fill"
                        color="green"
                        @click.prevent="downloadFactura(item)"
                        v-tooltip="'Descargar Factura'"
                      ></f7-link>
                      <f7-link 
                        v-if="item.estado.estado == 'CREADA' || item.estado.estado == 'ENVIADA'" 
                        icon-f7="arrow_2_circlepath_circle" 
                        color="orange" 
                        popup-open=".popup-cambio-estado" 
                        v-tooltip="'Cambiar estado'"
                        @click="facturaSelected = item"
                      ></f7-link>
                      <f7-link
                        v-if="item.estado.estado != 'PAGADA' && item.estado.estado != 'RECHAZADA' && item.estado.estado != 'CANCELADA'"
                        icon-f7="clear"
                        color="red"
                        @click="facturaSelected = item"
                        popup-open=".popup-cancel-factura"
                        v-tooltip="'Cancelar Factura'"
                      ></f7-link>
                    </td>
                  </tr>
                </template>
                <template v-slot:after="{rlist}">
                  <f7-card-footer>
                    <span>
                      <b>{{$tc('rlist.n_registros', rlist.count, {count:rlist.count})}}</b>
                    </span>
                  </f7-card-footer>
                </template>
              </r-list>
            </f7-card-content>
          </f7-card>
        </f7-tab>
      </f7-tabs>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
    <f7-popup ref="popupPedido" class="popup-prepare-pedido" swipe-to-close>
      <f7-page>
        <f7-navbar title="Preparar Cita">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <f7-button
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rformpedido.saveItem()"
        ></f7-button>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
</style>
<style>
.color-theme-red .item-input-outline .item-input-wrap:after{
  border-color: var(--f7-input-outline-focused-border-color, var(--f7-theme-color));
}
.color-theme-orange .item-input-outline .item-input-wrap:after{
  border-color: var(--f7-input-outline-focused-border-color, var(--f7-theme-color));
}
.color-theme-green .item-input-outline .item-input-wrap:after{
  border-color: var(--f7-input-outline-focused-border-color, var(--f7-theme-color));
}
</style>
<script>
import Vue from "vue";
import rList from "./../../components/rList.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rFiles from "./../../components/rFiles.vue";
import Pedido from "./../../js/db/Pedido.js";
import Cliente from "./../../js/db/Cliente.js";

export default {
  components: {
    rList,
    rForm,
    rInput,
    rAutocomplete,
    rTimepicker,
    rFiles
  },
  data: function() {
    var self = this;
    return {
      Pedido,
      Cliente,
      readonly: !this.editable,
      pedidoSelected: {}
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    subtotal: function() {
      var self = this;
      return Pedido.getSubtotal(self.pedidoSelected);
    },
    importeTotal: function() {
      var self = this;
      return Pedido.getImporteTotal(self.pedidoSelected);
    },
    horasTotales: function() {
      var self = this;
      return Pedido.getHorasTotales(self.pedidoSelected);
    },
    facturado: function() {
      var self = this;
      return Pedido.getFacturado(self.pedidoSelected);
    },
    pendienteFacturar: function() {
      var self = this;
      return Pedido.getPendienteFacturar(self.pedidoSelected);
    },
    cobrado: function() {
      var self = this;
      return Pedido.getCobrado(self.pedidoSelected);
    },
    pendienteCobrar: function() {
      var self = this;
      return Pedido.getPendienteCobrar(self.pedidoSelected);
    },
    porcentajeFacturado: function() {
      var self = this;
      return self.facturado / self.importeTotal;
    },
    porcentajeCobrado: function() {
      var self = this;
      return self.cobrado / self.importeTotal;
    }
  },
  watch: {
    'pedidoSelected.clienteId': {
      handler: function(newClient){
        var self = this;
        if(newClient.precioHabitual){
          self.$set(self.pedidoSelected, "precio", newClient.precioHabitual );
        }
      }
    }
  },
  beforeDestroy() {},
  methods: {
    loadItem: function(res) {
      var self = this;
      res.facturasAsociadas = res.facturasAsociadas || [];
      res.precio = res.cliente.precioHabitual;
      self.pedidoSelected = res;
      self.$refs.facturasAsociadas.load(res.facturasAsociadas);
    },
    deleteLinea: function(index) {
      var self = this;
      self.$delete(self.$refs.rform.formData.lineas,index);
    },
    formReady: function(res) {
      var self = this;
      if (res) {
        for (let index = 0; index < res.lineas.length; index++) {
          self.$set(res.lineas[index], 'inicial', true);
        }
      }
      self.pedidoSelected = res;
    },
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var o = Object.assign({}, data);
      o.clienteId = o.cliente.id;
      delete o.cliente;
      resolve(o);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.pedidoSelected = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("pedidos.pedido_form.pedido_guardado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
      router.navigate(`/pedidos/view/${res.id}/`, {
        reloadCurrent: true
      });
    },
    navegarAFactura: function(factura) {
      var self = this;
      var $router = self.$f7router
      $router.navigate(`/facturas/view/${factura.id}/`);
    },
    getEstadoBadge: function(estado) {
      switch (estado) {
        case "CREADA":
          var texto = "Creada";
          var color = "gray";
          break;
        case "ENVIADA":
          var texto = "Enviada";
          var color = "blue";
          break;
        case "PAGADA":
          var texto = "Pagada";
          var color = "green";
          break;
        case "RECHAZADA":
          var texto = "Rechazada";
          var color = "red";
          break;
        case "CANCELADA":
          var texto = "Cancelada";
          var color = "red";
          break;
        case "COPIA_ORIGINAL":
          var texto = "Copia Original";
          var color = "gray";
          break;
        default:
          var texto = estado;
          var color = "gray";
      }
      return `<span class="badge color-` + color + `">` + texto + `</span>`;
    },
    clasePorPorcentaje: function(porcentaje) {
      if (porcentaje == 1) {
        return "green";
      }
      if (porcentaje > 0.5) {
        return "orange";
      }
      return "red";
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
    self.$refs.facturasAsociadas.load(self.$refs.rform.formData.facturasAsociadas);
  }
};
</script>