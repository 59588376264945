import { routesMain, routesLeft } from '../pages/routes-main.js';
import pedidos from '../pages/pedidos/routes.js';
import clientes from '../pages/clientes/routes.js';
import usuarios from '../pages/usuarios/routes.js';
import entidades from '../pages/entidades/routes.js';
import facturas from '../pages/facturas/routes.js';
import config from '../pages/config/routes.js';

var routes = [
    ...usuarios,
    ...entidades,
    ...facturas,
    ...pedidos,
    ...clientes,
    ...config,
    ...routesMain
];
export { routes, routesLeft };