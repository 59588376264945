import Clientes from './clientes.vue';
import ClienteForm from './cliente-form.vue';
import RoutesUtils from './../../js/RoutesUtils.js';

var routes = [{
        path: '/clientes/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('clientes', ['ADMIN'], Clientes),
    },
    {
        path: '/clientes/new/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('clientes/new', ['ADMIN'], ClienteForm)
    },
    {
        path: '/clientes/edit/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('clientes/edit', ['ADMIN'], ClienteForm)
    },
    {
        path: '/clientes/view/:id/',
        beforeEnter: RoutesUtils.checkAuthPage,
        async: RoutesUtils.checkPermission('clientes/view', ['ADMIN'], ClienteForm, { editable: false })
    },
];
export default routes;