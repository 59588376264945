<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('usuarios.usuario_form.editar_usuario')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('usuarios.usuario_form.usuario')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('usuarios.usuario_form.nuevo_usuario')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly && id"
          fill
          icon-f7="trash"
          text="Eliminar"
          color="red"
          class="display-flex"
          @click="$refs.rform.deleteItem()"
        ></f7-button>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      @loadItem="loadItem"
      :readonly="readonly"
      :dbAdapter="Usuario"
      :beforeSave="beforeSave"
      :defaultData="{clientes:[]}"
    >
      <f7-card class="padding-vertical-half padding-right" style="z-index: 2;">
        <f7-card-content>
          <f7-row no-gap class="list list-form no-hairlines">
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.username')"
                  :form="rform"
                  type="text"
                  name="username"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.nombre')"
                  :form="rform"
                  type="text"
                  name="nombre"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.apellidos')"
                  :form="rform"
                  type="text"
                  name="apellidos"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="50">
              <ul>
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.telefono')"
                  :form="rform"
                  type="text"
                  name="telefono"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.email')"
                  :form="rform"
                  type="text"
                  name="email"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('usuarios.usuario_form.iniciales')"
                  :form="rform"
                  type="text"
                  name="iniciales"
                ></r-input>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>

      <!-- Clientes Asociados -->
      <f7-block-title v-if="rform.formData.rol && (rform.formData.rol == 'ADMINISTRATIVO' || rform.formData.rol == 'CLIENTE')">
        <div 
          v-if="rform.formData.rol && rform.formData.rol == 'ADMINISTRATIVO'"
        > ** Si a un usuario 'ADMINISTRATIVO' no se le asignan clientes, podrá ver todos
        </div>
        {{$t('usuarios.usuario_form.clientes_asociados')}} 
        <f7-link v-if="!rform.readonly" @click="rform.formData.clientes.push({})">{{$t('usuarios.usuario_form.add')}}</f7-link>
      </f7-block-title>

      <!-- Clientes asociados -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
        v-for="(item,index) in rform.formData.clientes"
        :key="'cliente-'+index"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <f7-col width="100" medium="100">
                  <r-autocomplete
                    ref="cliente"
                    :dbAdapter="Cliente"
                    floating-label
                    :label="$t('usuarios.usuario_form.cliente')"
                    :form="rform"
                    :name="'clientes.'+index"
                    labelProp="nombre"
                    idProp="id"
                  ></r-autocomplete>
                </f7-col>
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
        <f7-link
          v-if="!rform.readonly"
          @click="$delete(rform.formData.clientes,index)"
          class="margin-half"
          style="position:absolute;right:0;top:0;"
          icon-f7="trash"
        ></f7-link>
      </f7-card>

      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
  </f7-page>
</template>
<style scoped>
  label.btn {
    padding: 1px;
    cursor: pointer;
    border: 1px solid;
  }
  .label-group {
    margin-top: 12px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rFiles from "./../../components/rFiles.vue";
import Usuario from "./../../js/db/Usuario.js";
import Cliente from "./../../js/db/Cliente.js";
import UsuarioCliente from "./../../js/db/UsuarioCliente.js";
import Documento from "./../../js/db/Documento.js";

export default {
  components: {
    rForm,
    rInput,
    rAutocomplete,
    rTimepicker,
    rFiles
  },
  data: function() {
    var self = this;
    return {
      Usuario,
      Cliente,
      UsuarioCliente,
      Documento,
      readonly: !this.editable,
      usuarioClienteId: undefined,
      newFotoSelected : false,
      newFirmaSelected : false
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  beforeDestroy() {},
  methods: {
    changeRol: function(e) {
      var self = this;
      UsuarioCliente.get({itemId: self.$refs.rform.formData.username}).then(function(data){
        self.$set(self.$refs.rform.formData, 'clientes', data && data.clientesId ? data.clientesId : []);
        self.usuarioClienteId = data && data.id ? data.id : undefined;
      });
    },
    loadItem: function(res) {
      var self = this;
      if (res && res.application && res.application.roles && res.application.roles.length > 0) {
        self.$set(self.$refs.rform.formData, 'rol', res.application.roles[0]);
      } 

      UsuarioCliente.get({itemId: res.username}).then(function(data){
        self.$set(self.$refs.rform.formData, 'clientes', data && data.clientesId ? data.clientesId : []);
        self.usuarioClienteId = data && data.id ? data.id : undefined;
      });

    },
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var o = Object.assign({}, data);
      o.application = {};
      o.application.roles = [o.rol];
      if (o.rol == 'ADMINISTRATIVO') {
        o.createInSecurity = true;
      }
      delete o.rol;
      resolve(o);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      res.rol = res.application.roles[0];

      var clientesId = self.$refs.rform.formData.clientes.map(function(e){
        return e && e.id ? e.id : e;
      });
      
      var usuarioClienteData = {
        clientesId: clientesId, 
        username: res.username,
        email: res.email,
        rol: res.rol
      }

      UsuarioCliente.save({itemId: self.usuarioClienteId, data: usuarioClienteData}).then(function(data){
        res.clientes = data.clientesId;
        self.$refs.rform.formData = res;  
        self.readonly = true;
        app.toast
          .create({
            text: self.$t("usuarios.usuario_form.usuario_guardado"),
            position: "center",
            closeTimeout: 2000,
            icon: '<i class="f7-icons">checkmark_alt</i>'
          })
          .open();
      });
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>