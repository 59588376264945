import DatabaseModel from './DatabaseModel.js';

var Empleado = Object.create(DatabaseModel);

Empleado.model = 'user';

Empleado.getList = function(params) {
    var self = this;
    params = params || {};
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + 'security/' + self.model + '/CONTABILIDAD',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Empleado.get = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server + 'security/' + self.model + '/CONTABILIDAD/' + params.itemId + '/',
            method: 'GET',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: {}
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
};

Empleado.save = function(params) {
    var self = this;
    if (params.itemId) {
        return self.put(params);
    } else {
        return self.post(params);
    }
}

Empleado.put = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        if (!params.itemId) reject({ error: 'itemId not defined' });
        self.request.promise({
            url: self.server + 'security/' + self.model + '/CONTABILIDAD/' + params.itemId + '/',
            method: 'PUT',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(xhr?JSON.parse(xhr.response).error:"Un error ha impedido realizar la operación.");
            } catch (e) {
                reject(xhr?xhr.response:"Un error ha impedido realizar la operación.");
            }
        });
    });
}

Empleado.post = function(params) {
    var self = this;
    return new Promise(function(resolve, reject) {
        self.request.promise({
            url: self.server + 'security/' + self.model + '/CONTABILIDAD/',
            method: 'POST',
            contentType: 'application/json',
            dataType: 'json',
            crossDomain: true,
            data: params.data
        }).then(function({ data, xhr, status }) {
            if (data.error) {
                reject(data);
            } else {
                resolve(data.dto);
            }
        }).catch(function({ xhr, status, message }) {
            try {
                reject(JSON.parse(xhr.response).error);
            } catch (e) {
                reject(xhr.response);
            }
        });
    });
}

export default Empleado;