<template>
  <f7-page :page-content="true">
    <f7-navbar :back-link="$t('atras')">
      <f7-nav-title v-if="id && !readonly" sliding>{{$t('facturas.editar_factura')}}</f7-nav-title>
      <f7-nav-title v-else-if="id" sliding>{{$t('facturas.factura')}}</f7-nav-title>
      <f7-nav-title v-else sliding>{{$t('facturas.generar')}}</f7-nav-title>
      <f7-nav-right>
        <f7-button
          v-if="!readonly"
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
        <f7-button
          v-if="readonly"
          fill
          icon-f7="arrow_2_squarepath"
          :text="$t('facturas.asociar_pedido')"
          class="display-flex"
          popup-open=".popup-asociar-pedido" 
        ></f7-button>
      </f7-nav-right>
    </f7-navbar>
    <r-form
      ref="rform"
      v-slot="{rform}"
      :itemId="id"
      @save="onSave"
      @loadItem="loadItem"
      :defaultData="{facturaItem:[], pedidoAsociado: {}}"
      :readonly="readonly"
      :dbAdapter="Factura"
    >
      <f7-row class="padding-vertical-half padding-right">
        <f7-col width="100" medium="75">
        </f7-col>
        <f7-col width="100" medium="25">
        </f7-col>
      </f7-row>
      <f7-block-title>
        {{$t('facturas.factura')}}
      </f7-block-title>
      <f7-card :style="'z-index:'+(rform.formData.facturaItem.length + 1)">
        <f7-card-content>
          <f7-row class="list list-form no-hairlines padding-vertical" :style="'z-index:'+(rform.formData.facturaItem.length + 1)">
            <f7-col width="100" medium="33">
              <ul>
                <r-autocomplete
                  ref="cliente"
                  class="col-33"
                  :dbAdapter="Cliente"
                  floating-label
                  :label="$t('facturas.cliente')"
                  :form="rform"
                  name="clienteId"
                  idProp="id"
                  labelProp="nombre"
                  :readonly="true"
                ></r-autocomplete>
              </ul>
            </f7-col>
            <f7-col width="100" medium="33">
              <ul>
                <r-autocomplete
                  ref="pedido"
                  class="col-33"
                  :dbAdapter="Pedido"
                  :filter="filterPedido"
                  floating-label
                  :label="$t('facturas.pedido')"
                  :form="rform"
                  name="pedidoAsociadoId"
                  idProp="id"
                  labelProp="name"
                  :source="function(self) {
                    return function(query, render) {
                      var results = [];
                      for (var i = 0; i < self.items.length; i++) {
                        if (!Pedido.isFinalizado(self.items[i])
                          && self.items[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0
                          && (!self.filter.clienteId || self.items[i].clienteId == self.filter.clienteId))
                          results.push(self.items[i]);
                      }
                      render(results);
                    };
                  }"
                ></r-autocomplete>
              </ul>
            </f7-col>
            <f7-col width="100" medium="33">
            </f7-col>
          </f7-row>
          <f7-row class="list list-form no-hairlines">
            <f7-col width="100" medium="33">
              <ul>
                <r-input
                  floating-label
                  :label="$t('facturas.numero')"
                  :form="rform"
                  type="text"
                  name="numero"
                  :readonly="true"
                ></r-input>
                <r-input
                  floating-label
                  :label="$t('facturas.estado')"
                  :form="rform"
                  type="text"
                  name="estado.estado"
                  :readonly="true"
                ></r-input>
                <r-input
                  v-if="rform.formData.estado && rform.formData.estado.estado == 'CANCELADO'"
                  floating-label
                  :label="$t('facturas.motivo_cancel')"
                  :form="rform"
                  type="text"
                  name="estado.mensaje"
                  :readonly="true"
                >
                </r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="33">
              <ul>
                <r-input
                  class="col-50"
                  floating-label
                  :label="$t('facturas.fecha_emision')"
                  :form="rform"
                  type="datepicker"
                  name="fechaEmision"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
                <r-input
                  class="col-50"
                  floating-label
                  :label="$t('facturas.fecha_vencimiento')"
                  :form="rform"
                  type="datepicker"
                  name="fechaVencimiento"
                  :calendar-params="{closeOnSelect:true,routableModals:false, dateFormat: { year:'numeric', month: '2-digit', day: '2-digit' } }"
                ></r-input>
              </ul>
            </f7-col>
            <f7-col width="100" medium="33">
              <ul>
                <f7-list-input
                  :readonly="true"
                  floating-label
                  :label="$t('facturas.subtotal')"
                  type="number"
                  :value="subtotal"
                ></f7-list-input>
                <f7-list-input
                  :readonly="true"
                  floating-label
                  :label="$t('facturas.totalIva')"
                  type="number"
                  :value="totalIva"
                ></f7-list-input>
                <f7-list-input
                  :readonly="true"
                  floating-label
                  :label="$t('facturas.totalFactura')"
                  type="number"
                  :value="totalFactura"
                ></f7-list-input>
              </ul>
            </f7-col>
          </f7-row>
        </f7-card-content>
      </f7-card>
      <div v-if="!rform.readonly || (rform.formData && rform.formData.comentarios && rform.formData.comentarios.length)">
        <f7-block-title>
          {{$t('facturas.comentarios')}} <f7-link v-if="!rform.readonly && rform.formData && rform.formData.comentarios" @click="rform.formData.comentarios.push({})">{{$t('facturas.add')}}</f7-link>
        </f7-block-title>
        <f7-card class="padding-vertical-half padding-right">
          <f7-card-content>
            <f7-row no-gap class="list list-form no-hairlines">
              <f7-col width="100" medium="100">
                <ul>
                  <f7-row v-for="(comentario, index) in rform.formData.comentarios" :key="'comentario-'+index" style="position: relative">
                    <r-input
                      class="col-100"
                      floating-label
                      :form="rform"
                      :name="'comentarios.'+index+'.comentario'"
                    ></r-input>
                    <f7-link
                      v-if="!rform.readonly && rform.formData.comentarios.length > 1"
                      @click="$delete(rform.formData.comentarios,index)"
                      class="margin-half"
                      style="position:absolute;right:0;top:0;"
                      icon-f7="trash"
                    ></f7-link>
                  </f7-row>
                </ul>
              </f7-col>
            </f7-row>
          </f7-card-content>
        </f7-card>
      </div>
      <f7-block-title>
        {{$t('facturas.lineas')}} <f7-link v-if="!rform.readonly && rform.formData && rform.formData.facturaItem" @click="rform.formData.facturaItem.push({})">{{$t('facturas.add')}}</f7-link>
      </f7-block-title>

      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
        v-for="(item,index) in rform.formData.facturaItem"
        :key="'linea-'+index"
        :style="'z-index:'+(rform.formData.facturaItem.length - index)"
      >
        <f7-card-content>
          <f7-list class="list-form">
            <ul>
              <f7-row>
                <!-- <f7-col width="100" medium="50"> -->
                  <r-input
                    class="col-100"
                    floating-label
                    :label="$t('facturas.concepto')"
                    :form="rform"
                    type="text"
                    :name="'facturaItem.'+index+'.concepto'"
                    :readonly="rform.readonly"
                  ></r-input>
                  <r-input
                    class="col-100 medium-33"
                    floating-label
                    :label="$t('facturas.cantidad')"
                    :form="rform"
                    type="text"
                    :name="'facturaItem.'+index+'.cantidad'"
                    :readonly="rform.readonly"
                  ></r-input>
                  <r-input
                    class="col-100 medium-33"
                    floating-label
                    :label="$t('facturas.precio_unitario')"
                    :form="rform"
                    type="number"
                    step="0.01"
                    :name="'facturaItem.'+index+'.precioUnitario'"
                    :readonly="rform.readonly"
                  ></r-input>
                  <r-input
                    class="col-100 medium-33"
                    floating-label
                    :label="$t('facturas.tipoIva')"
                    :form="rform"
                    type="text"
                    :name="'facturaItem.'+index+'.tipoIva'"
                    :readonly="rform.readonly"
                  ></r-input>
                  <f7-link
                    v-if="!rform.readonly && rform.formData.facturaItem.length > 1"
                    @click="$delete(rform.formData.facturaItem,index)"
                    class="margin-half"
                    style="position:absolute;right:0;top:0;"
                    icon-f7="trash"
                  ></f7-link>
                <!-- </f7-col> -->
              </f7-row>
            </ul>
          </f7-list>
        </f7-card-content>
      </f7-card>
      <pre style="display:none;">{{rform.formData}}</pre>
    </r-form>
    <factura-modal-pedido 
      :factura="facturaActual"
      @save="reloadData"
      ></factura-modal-pedido>
  </f7-page>
</template>
<style scoped>
.label-group {
  margin-top: 12px;
  margin-left: 20px;
  font-weight: bold;
}
</style>
<script>
import Vue from "vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import facturaModalPedido from "./factura-modal-pedido.vue";
import Factura from "./../../js/db/Factura.js";
import Pedido from "./../../js/db/Pedido.js";
import Cliente from "./../../js/db/Cliente.js";

export default {
  components: {
    rForm,
    rInput,
    rAutocomplete,
    facturaModalPedido,
  },
  data: function() {
    var self = this;
    return {
      Factura,
      Pedido,
      Cliente,
      readonly: !this.editable,
      facturaActual: {}
    };
  },
  props: {
    id: {
      type: String
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    filterPedido: function(){
      var self = this;
      if(self.facturaActual.clienteId){
        return { clienteId: self.facturaActual.clienteId};
      }
      return {};
    },
    subtotal: function(){
      var self = this;
      return (Factura.getSubtotal(self.facturaActual));
    },
    totalIva: function(){
      var self = this;
      return (Factura.getTotalIva(self.facturaActual));
    },
    totalFactura: function(){
      var self = this;
      return (Factura.getTotalFactura(self.facturaActual));
    }
  },
  beforeDestroy() {},
  methods: {
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.$refs.rform.formData = res;
      self.readonly = true;
      app.toast
        .create({
          text: self.$t("facturas.factura_guardada"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    loadItem: function(res) {
      var self = this;
      if(res.comentarios){
        res.comentarios = res.comentarios.filter((comentario) => comentario.comentario);
      }
      if(res.estado.estado != "CREADA"){
        self.readonly = true;
      }
      if(!self.readonly && (!res.comentarios || !res.comentarios.length)){
        res.comentarios = [{}];
      }
      if(!res.pedidoAsociado){
        res.pedidoAsociado = {};
      }
      self.facturaActual = res;
    },
    reloadData: function(res){
      var self = this;
      self.$refs.rform.formData = res;
    }
  },
  mounted: function() {
    var self = this;
    var $ = self.$$;
    var $el = $(self.$el);
    var app = self.$f7;
  }
};
</script>