const messages = {
    en: {

    },

    es: {
        atras: 'Atrás',
        cerrar: 'Cerrar',
        aceptar: 'Aceptar',
        guardar: 'Guardar',
        common: {
            cambiarFiltros: 'Cambiar Filtros',
            cerrar: 'Cerrar',
            campoVacio: 'Campo Vacío',
            campoRelleno: 'Campo Relleno',
            fechaVacia: 'Fecha Vacía',
            fechaRellena: 'Fecha Rellena',
            mesActual: 'Mes Actual',
            yes: 'Sí',
            no: 'No',
            desde: 'Desde',
            hasta: 'Hasta',
            buscar: 'Buscar',
            anadir: 'Añadir',
            cargar: 'Cargar',
            ver: 'Ver',
            editar: 'Editar',
            duplicar: 'Duplicar',
            guardar: 'Guardar',
            aceptar: 'Aceptar',
            guardarComo: 'Guardar Como',
            eliminar: 'Eliminar',
            cancelar: 'Cancelar',
            compartir: 'Compartir',
            filtro: 'Filtro',
            nombre: 'Nombre',
            bloqueado: 'Bloqueado',
            desbloqueado: 'Desbloqueado',
            move: 'Mover',
            copy: 'Copiar',
            paste: 'Pegar',
            any: 'Cualquiera',
            actualizarTabla: 'Actualizar datos de tabla',
            exportarExcel: 'Exportar a Excel',
            consultasFavoritas: 'Consultas Favoritas',
            firstSearch: 'Realice una búsqueda para obtener resultados',
            firstSearchTipoEstatico: 'Elija una consulta para obtener resultados',
            noResults: 'La búsqueda no ha dado resultados',
            configurarColumnas: 'Configurar columnas',
            consultaGuardada: 'Consulta Guardada correctamente',
            consultaBorrada: 'Consulta Borrada correctamente',
            consultaCompartida: 'Consulta Compartida correctamente',
            filtrar: 'Filtrar',
            cleanFilter: 'Limpiar Filtro',
            configurarFiltros: 'Configurar Filtros',
            guardarConsulta: 'Guardar Consulta',
            sharedConsulta: 'Compartir Consulta',
            sobreescribir: 'Sobreescribir',
            cambiosmasivos: 'Cambios Masivos',
            actualizarMasivamente: 'Actualizar masivamente',
            desmarcarSeleccionados: 'Desmarcar Seleccionados',
            configurarAgregaciones: 'Configurar Agregaciones',
            ver: 'Ver',
            sin: 'Sin',
            borrarFiltro: "Borrar Filtro",
            today: "Hoy",
            consultasComandos: "Elija una consulta"
        },
        main_menu: {
            inicio: 'Inicio',
            clientes: 'Clientes',
            pedidos: 'Pedidos',
            facturas: 'Facturas',
            usuarios: 'Usuarios',
            entidades: 'Entidades',
            configuracion: 'Configuración',
            cerrar_sesion: 'Cerrar sesión'
        },
        componentes: {
            direccion: {
                codigo_postal: 'Código Postal',
                direccion: 'Dirección',
                poblacion: 'Población',
                provincia: 'Provincia',
                comunidad: 'Comunidad Autónoma',
                pais: 'País',
            }
        },
        perfil: {
            perfil: 'Perfil',
            cambiar_pass: 'Cambiar Contraseña',
            new_pass: 'Nueva Contraseña',
            repeat_new_pass: 'Repita Nueva Contraseña',
            actual_pass: 'Contraseña Actual',
            cliente_contacto: 'Contacto',
            nombre: 'Nombre',
            firma: 'Añadir firma',
        },
        usuarios: {
            login: {
                usuario: 'Usuario',
                contrasena: 'Contraseña',
                entrar: 'Entrar',
                olvide_mi_contrasena: 'Olvidé mi contraseña'
            },
            usuarios: {
                usuarios: 'Usuarios'
            },
            usuario_form: {
                username: 'Username',
                nombre: 'Nombre',
                apellidos: 'Apellidos',
                telefono: 'Teléfono',
                email: 'Email',
                iniciales: 'Iniciales',
                roles: 'Roles',
                usuario: 'Usuario',
                editar_usuario: 'Editar usuario',
                nuevo_usuario: 'Nuevo usuario',
                usuario_guardado: 'Usuario guardado',
                rol: 'Rol',
                clientes_asociados: 'Clientes asociados',
                cliente: 'Cliente',
                add: 'Añadir',
                foto: 'Seleccionar Foto',
                firma: 'Firma',
                select_firma: 'Seleccionar Firma'
            }
        },
        entidades: {
            entidades: {
                entidades: 'Entidades'
            },
            entidad_form: {
                datos_entidad: 'Datos de entidad',
                nombre: 'Nombre',
                cif: 'CIF',
                tipoCliente: 'Tipo de cliente',
                ubicacion: 'Ubicación',
                eMail: 'Email',
                razonSocial: 'Razón social',
                codigo_postal: 'Código postal',
                direccion: 'Dirección',
                poblacion: 'Población',
                provincia: 'Provincia',
                pais: 'País',
                entidad: 'Entidad',
                usuario: 'Usuario',
                rol: 'Rol',
                editar_entidad: 'Editar entidad',
                nueva_entidad: 'Nueva entidad',
                cliente_guardado: 'Cliente guardado',
                hora_apertura: 'Hora Apertura',
                hora_cierre: 'Hora Cierre',
                direcciones: 'Direcciones',
                direccionSocial: 'Dirección social',
                direccionFacturacion: 'Dirección de facturación',
                usuariosAsociados: 'Usuarios Asociados',
                imagen: 'Seleccionar Imagen',
                add: 'Añadir',
                invitar: 'Invitar',
                alcance: 'Alcance'
            }
        },
        facturas: {
            facturas: 'Facturas',
            numero: 'Número Factura',
            estado: 'Estado',
            cliente: 'Cliente',
            pedido: 'Pedido',
            fecha_emision: 'F. Emisión',
            fecha_vencimiento: 'F. Vto',
            subtotal: 'Subtotal',
            totalIva: 'Total IVA',
            totalFactura: 'Total Factura',
            lineas: 'Líneas',
            comentarios: 'Comentarios',
            desde: 'Desde',
            hasta: 'Hasta',
            generar: 'Generar Factura',
            a_quien_facturar: 'A Quién realizar las facturas',
            facturar_hasta_dia: 'Facturar hasta el día:',
            que_facturar: 'Qué Facturar',
            comoFacturar: 'Facturar a nombre de:',
            envio_email_automatico: 'Enviar email con la factura automáticamente a los clientes',
            factura_guardada: 'Factura Guardada',
            concepto: 'Concepto',
            codigo_pedido: 'Cita',
            matricula: 'Matrícula',
            bastidor: 'Bastidor',
            total: 'Total',
            tipoIva: 'Tipo IVA',
            precio_unitario: 'Precio Unitario',
            cantidad: 'Cantidad',
            factura: 'Factura',
            add: 'Añadir',
            invitar: 'Invitar',
            editar_factura: 'Editar Factura',
            motivo_cancel: 'Motivo Cancelación',
            facturas_generadas: 'Facturas Generadas',
            asociar_pedido: 'Asociar pedido',
            pedido_asociado_correctamente: 'Pedido asociado correctamente',
        },
        pedidos: {
            pedidos: {
                pedidos: 'Pedidos'
            },
            estados: {
                CERRADO: "Cerrado",
                LISTO: "Listo",
                COBRADO: "Cobrado",
                COBRADO_PARCIAL: "Parcialmente cobrado",
                FACTURADO: "Facturado",
                FACTURADO_PARCIAL: "Parcialmente facturado",
                CREADO: "Creado"
            },
            pedido_form: {
                nombre: 'Nombre',
                editar_pedido: 'Editar pedido',
                cerrar_pedido: 'Cerrar pedido',
                nuevo_pedido: 'Nuevo pedido',
                pedido_guardado: 'Pedido guardada con éxito',
                ver_pedido: 'Ver pedido',
                pedido: 'Pedido',
                codigo: 'Código',
                estado: 'Estado',
                codigoCliente: 'Código de cliente',
                cliente: 'Cliente',
                fecha: 'Fecha',
                concepto: 'Concepto',
                horas_totales: 'Horas Totales',
                precio_defecto: 'Precio por Defecto',
                importe_total: 'Importe Total',
                ofertas: 'Ofertas',
                oferta: 'Oferta',
                resumen: 'Resumen',
                facturado: 'Facturado',
                pendiente_facturar: 'Pendiente de facturar',
                cobrado: 'Cobrado',
                pendiente_cobrar: 'Pendiente de cobrar',
                lineas: 'Lineas',
                no_lineas: 'Aún no se han incluido líneas al pedido.',
                facturas_asociadas: 'Facturas asociadas',
                sin_facturas_asociadas: 'Sin facturas asociadas.',
                iva: 'IVA',
                horas: 'Horas',
                precio: 'Precio',
                subtotal: 'Subtotal',
                importe: 'Importe',
                tipo_pedido: 'Tipo de pedido',
                fecha: 'Fecha',
                asociar_factura: 'Asociar facturas',
                fecha_solicitada_desde: 'Fecha Solicitada Desde',
                fecha_solicitada_hasta: 'Fecha Solicitada Hasta',
                fecha_visita: 'Fecha visita',
                hora: 'Hora',
                hora_inicio: 'Hora inicio',
                hora_fin: 'Hora fin',
                dia: 'Día',
                cif: 'CIF',
                codigo_postal: 'Código postal',
                direccion: 'Dirección',
                poblacion: 'Población',
                provincia: 'Provincia',
                pais: 'País',
                estado: 'Estado',
                importe: 'Importe',
                pedido_preparado: 'Pedido preparada correctamente, se han creado sus certificaciones',
                motivo_cancel: 'Motivo cancelación',
                fecha_creacion_desde: 'Fecha Creación Desde',
                fecha_creacion_hasta: 'Fecha Creación Hasta',
                fecha_creacion: 'Fecha Creación',
                add: 'Añadir',
                desde: 'Desde',
                hasta: 'Hasta',
                usuario: 'Usuario',
                departamento: 'Departamento',
                comision: 'Comisión',
                confirmar_cierre: '¿Está seguro de que desea cerrar el pedido?',
                pedido_cerrado: 'Pedido cerrado',
            }
        },
        clientes: {
            clientes: {
                clientes: 'Clientes'
            },
            cliente_form: {
                datos_cliente: 'Datos de cliente',
                nombre: 'Nombre',
                cif: 'CIF',
                tipoCliente: 'Tipo de cliente',
                ubicacion: 'Ubicación',
                eMail: 'Email',
                razonSocial: 'Razón social',
                precioHabitual: 'Precio habitual',
                clientePadre: 'Cliente padre',
                codigo_postal: 'Código postal',
                direccion: 'Dirección',
                poblacion: 'Población',
                provincia: 'Provincia',
                pais: 'País',
                cliente: 'Cliente',
                editar_cliente: 'Editar cliente',
                nuevo_cliente: 'Nuevo cliente',
                cliente_guardado: 'Cliente guardado',
                hora_apertura: 'Hora Apertura',
                hora_cierre: 'Hora Cierre',
                direccion: 'Dirección',
                configuraciones: 'Configuraciones',
                tiempo: 'Tiempo defecto',
                precio: 'Precio',
                tipo_configuracion: 'Tipo Configuración',
                valor: 'Valor',
                matricula: 'Matrícula',
                bastidor: 'Bastidor',
                certificacion_1: 'Certificaciones de 1as',
                certificacion_2: 'Certificaciones de 2as',
                imagen: 'Seleccionar Imagen',
                add: 'Añadir',
                alcance: 'Alcance'
            }
        },
        configuracion: {
            configuracion: 'Configuración',
            politica_privacidad: 'Política de privacidad',
            tema_oscuro: 'Tema oscuro',
            idioma: {
                idioma: 'Idioma',
                espanol: 'Español',
                ingles: 'Inglés'
            }
        },
        rlist: {
            pregunta_eliminar_registro: "¿Está seguro de que desea eliminar este registro?",
            registro_eliminado: "Registro eliminado con éxito",
            n_registros_seleccionados: "{count} registro seleccionado | {count} registros seleccionados",
            n_registros: "{count} registro | {count} registros"
        },
        rform: {
            error_subiendo_archivos: "Error subiendo archivos",
            error_validacion_formulario: "Hay errores en el formulario"
        }
    }
}
export default messages;