<template>
  <f7-page :page-content="false" @page:beforeremove="onPageBeforeRemove">
    <!-- Top Navbar -->
    <f7-navbar :title="$t('pedidos.pedidos.pedidos')" :back-link="$t('atras')"></f7-navbar>
    <!-- Page content-->
    <f7-page-content class="full-height">
      <!-- rList Table CARD-->
      <r-dynamic-list
        ref="rlist"
        :dbAdapter="Pedido"
        :tableCode="'PEDIDO'"
        :tableMode="true"
        :virtualListParams="{height:32, rowsBefore:60, rowsAfter:60}"
        class="card full-height data-table"
        classContent="card-content full-height"
        :shownFields="['estado.estado']"
        :massiveActions="true"
        :funcShowCheck="showCheckInList"
        :controlsWidth="180"
      >
        <template v-slot:tableLinks>
          <a class="link" href="/pedidos/new/">{{$t("pedidos.pedido_form.nuevo_pedido")}}</a>
        </template>
        <template v-slot:optionsColumn="{item}">
          <f7-link
            icon-f7="eye"
            color="green"
            :href="`/pedidos/view/${item.id}/`"
            v-tooltip="$t('pedidos.pedido_form.ver_pedido')"
          ></f7-link>
          <f7-link
            v-if="true"
            icon-f7="square_pencil"
            color="blue"
            :href="`/pedidos/edit/${item.id}/`"
            v-tooltip="$t('pedidos.pedido_form.editar_pedido')"
          ></f7-link>
          <f7-link
            v-if="item.estado.estado != 'CERRADO' && item.estado.estado != 'CANCELADO'"
            icon-f7="lock_fill"
            color="green"
            @click="cerrarPedido(item)"
            v-tooltip="$t('pedidos.pedido_form.cerrar_pedido')"
          ></f7-link>
          <f7-link
            v-if="item.estado.estado != 'CANCELADO' && item.estado.estado != 'CERRADO'"
            icon-f7="clear"
            color="red"
            @click="pedidoSelected = item"
            popup-open=".popup-cancel-pedido"
            v-tooltip="'Cancelar Pedido'"
          ></f7-link>
        </template>
      </r-dynamic-list>
    </f7-page-content>
    <f7-popup ref="popupPedido" class="popup-prepare-pedido" swipe-to-close>
      <f7-page>
        <f7-navbar title="Preparar Cita">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <f7-button
          fill
          icon-f7="floppy_disk"
          text="Guardar"
          class="display-flex"
          @click="$refs.rform.saveItem()"
        ></f7-button>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
    <f7-popup ref="popupCancelPedido" class="popup-cancel-pedido" swipe-to-close>
      <f7-page>
        <f7-navbar title="Cancelar Cita">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
      <f7-card
        class="elevation-3 padding-vertical-half padding-right"
      >
      <f7-card-content>
        <f7-list class="list-form">
          <ul>
            <f7-row>
              <f7-col width="100" medium="100">
                <input
                  maxlength="256"
                  floating-label
                  :placeholder="$t('pedidos.pedido_form.motivo_cancel')"
                  type="text"
                  name="motivoCancel"
                  ref="motivoCancel"
                  class="padding-left"
                >
              </f7-col>
            </f7-row>
          </ul>
        </f7-list>
      </f7-card-content>
      <f7-button
          fill
          icon-f7="clear"
          text="Cancelar"
          class="display-flex"
          color="red"
          @click="cancelPedido()"
        ></f7-button>
      </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
    <f7-popup
      ref="popupCancelPedido"
      class="popup-cancel-pedido"
      swipe-to-close
    >
      <f7-page>
        <f7-navbar title="Cancelar Pedido">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>

        <!-- <div style="height: 100%" class="display-flex justify-content-center align-items-center"> -->
        <f7-card class="elevation-3 padding-vertical-half padding-right">
          <f7-card-content>
            <f7-list class="list-form">
              <ul>
                <f7-row>
                  <f7-col width="100" medium="100">
                    <input
                      maxlength="256"
                      floating-label
                      :placeholder="$t('pedidos.pedido_form.motivo_cancel')"
                      type="text"
                      name="motivoCancel"
                      ref="motivoCancel"
                      class="padding-left"
                    />
                  </f7-col>
                </f7-row>
              </ul>
            </f7-list>
          </f7-card-content>
          <f7-button
            fill
            icon-f7="clear"
            text="Cancelar"
            class="display-flex margin-left"
            color="red"
            @click="cancelPedido()"
          ></f7-button>
        </f7-card>
        <!-- </div> -->
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<style scoped>
  .label-group {
    margin-top: 20px;
    margin-left: 20px;
    font-weight: bold;
  }
</style>
<script>
import rDynamicList from "./../../components/rDynamicList.vue";
import rPanel from "./../../components/rPanel.vue";
import rForm from "./../../components/rForm.vue";
import rInput from "./../../components/rInput.vue";
import rBadge from "./../../components/rBadge.vue";
import rTimepicker from "./../../components/rTimepicker.vue";
import rAutocomplete from "./../../components/rAutocomplete.vue";
import Pedido from "./../../js/db/Pedido.js";
import Cliente from "./../../js/db/Cliente.js";
export default {
  components: {
    rDynamicList,
    rPanel,
    rForm,
    rInput,
    rBadge,
    rAutocomplete,
    rTimepicker
  },
  data() {
    return {
      Pedido,
      Cliente,
      rPanelOpenedByBreakpoint: false,
      filtroCliente: true,
      pedidoSelected: null,
      listData: []
    };
  },
  computed: {},
  methods: {
    cancelPedido: function() {
      var self = this;
      var app = self.$f7;
      var id = self.pedidoSelected.id;
      var mensaje = self.$refs.motivoCancel.value;
      if (!mensaje || mensaje == "") {
        app.dialog.alert("Debe introducir un motivo.");
        return;
      }
      self.$f7.dialog.confirm(
        "¿Esta seguro que desea cancelar el pedido seleccionado?",
        "Cancelar",
        function() {
          app.preloader.show();
          Pedido.delete({ itemId: id, data: { comentario: mensaje } })
            .then(function(res) {
              if (res) {
                app.toast
                  .create({
                    icon: '<i class="f7-icons">checkmark</i>',
                    text: "Pedido cancelada",
                    position: "center",
                    destroyOnClose: true,
                    closeTimeout: 2000
                  })
                  .open();
                self.$refs.motivoCancel.value = "";
                if (self.$refs.popupCancelPedido) {
                  self.$refs.popupCancelPedido.close(true);
                }
                self.reload();
              }
            })
            .catch(function(error) {
              app.dialog.alert(error.message);
            })
            .finally(_ => app.preloader.hide());
        }
      );
    },
    cerrarPedido: function(pedido) {
      var self = this;
      var app = self.$f7;
      var id = pedido.id;
      app.dialog.confirm(
        self.$t('pedidos.pedido_form.confirmar_cierre'),
        self.$t('cerrar'),
        function() {
          app.preloader.show();
          Pedido.cerrar(id).then(function(res) {
            if (res) {
              app.toast
                .create({
                  icon: '<i class="f7-icons">checkmark</i>',
                  text: self.$t('pedidos.pedido_form.pedido_cerrado'),
                  position: "center",
                  destroyOnClose: true,
                  closeTimeout: 2000
                })
                .open();
                self.reload();
            }
          }).catch(function(error){
            app.dialog.alert(error.message);
          }).then(function(){
            app.preloader.hide();
          });
        }
      );
    },
    showCheckInList: function(item) {
      return item.estado && item.estado.estado && item.estado.estado == "CREADO"; 
    },
    beforeSave: function(data, resolve, reject) {
      var self = this;
      var o = Object.assign({}, data);
      o.pedidoId = self.pedidoSelected.id;
      resolve(o);
    },
    onSave: function(res) {
      var self = this;
      var app = self.$f7;
      var router = self.$f7router;
      self.reload();
      self.$refs.rform.clear();
      if (self.$refs.popupPedido) {
        self.$refs.popupPedido.close(true);
      }
      app.toast
        .create({
          text: self.$t("pedidos.pedido_form.pedido_preparado"),
          position: "center",
          closeTimeout: 2000,
          icon: '<i class="f7-icons">checkmark_alt</i>'
        })
        .open();
    },
    onPageBeforeRemove() {
      const self = this;
      // Destroy popup when page removed
      if (self.popup) self.popup.destroy();
    },
    loadList: function(){
      var self = this;
      self.listData = self.$refs.rlist.vlData.items;
    },
    reload: function(){
      var self = this;
      Pedido.resetCache();
      self.$refs.rlist.refresh();
    }
  },
  mounted: function() {
    var self = this;
    var app = self.$f7;
  }
};
</script>